.vc_row.vc_column-gap-10 {
    margin-left: -20px;
    margin-right: -20px;
}
.vc_row[data-vc-full-width] {
    -webkit-transition: opacity .5s ease;
    -o-transition: opacity .5s ease;
    transition: opacity .5s ease;
    overflow: hidden;
}
.vc_row:after, .vc_row:before {
    content: " ";
    display: table;
}
.upb_row_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
}
.upb_bg_overlay, .upb_bg_overlay_pattern {
    color: inherit;
    position: absolute;
    width: 100%;
    height: 100%!important;
    left: 0;
    top: 0;
}
.vc_row.vc_column-gap-10>.vc_column_container {
    padding: 5px;
}
.upb_video_class, .wpb_column, .wpb_row {
    position: relative;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.vc_column-inner::after {
    clear: both;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.vc_custom_1674743395703 {
    margin-top: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
}
.aio-tooltip {
    display: inline-block;
    text-align: center;
    width: auto;
    max-width: 100%;
}
.aio-tooltip {
    display: inline-block;
    text-align: center;
    width: auto;
    max-width: 100%;
}
.aio-icon-img .img-icon {
    width: 1em;
    max-width: 100%!important;
    -webkit-border-radius: 0!important;
    border-radius: 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    display: block;
    font-size: inherit;
}
.comment-content img, .entry-content img {
    max-width: 100%;
    height: auto;
}
.uvc-heading {
    text-align: center;
}
.uvc-heading-spacer {
    margin: 0 auto;
    padding: 0;
    position: relative;
    overflow: hidden;
}
.uvc-heading.ultimate-heading-112064ccd8c1f4239 .uvc-sub-heading {
    font-size: 27px;
    line-height: 42px;
}
.uvc-sub-heading {
    line-height: normal;
    margin: 0 auto;
}
.uvc-heading p {
    word-break: normal;
    word-wrap: normal;
    -ms-word-break: normal;
    -ms-word-wrap: normal;
}
.uvc-heading.ultimate-heading-825964ccd8c200044 .uvc-sub-heading {
    font-size: 14px;
    line-height: 28px;
}
.uvc-sub-heading {
    line-height: normal;
    margin: 0 auto;
}
.ubtn-ctn-center {
    display: block;
    text-align: center;
}
a.ubtn-link {
    display: inline-block;
    margin: 0;
    color: inherit;
    text-decoration: none;
    max-width: 100%;
}
#ubtn-7934 {
    font-size: 15px;
}
a.ubtn-link .ubtn {
    margin: 0;
}
button.ubtn-custom {
    padding: 0;
}
.ubtn-sep-icon.ubtn-sep-icon-at-left .ubtn-icon {
    left: 20px;
    opacity: 1;
}
.ubtn-sep-icon .ubtn-icon {
    position: absolute;
    top: 50%;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 1;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.Defaults-edit:before {
    content: "\f044";
}
.ubtn-hover {
    opacity: 0;
}
.ubtn-hover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background: 0 0;
    z-index: 2;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-border-radius: inherit;
    border-radius: inherit;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.ubtn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.vc_row.vc_column-gap-10>.vc_column_container {
    padding: 5px;
}
.upb_video_class, .wpb_column, .wpb_row {
    position: relative;
}
.vc_col-has-fill>.vc_column-inner, .vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_column_container>.vc_column-inner {
    padding-top: 0px;
}
.vc_custom_1674743352367 {
    padding-right: 15px !important;
}
.aio-tooltip {
    display: inline-block;
    text-align: center;
    width: auto;
    max-width: 100%;
}
.aio-icon-img {
    display: inline-block;
    font-size: 48px;
    width: 1em;
    max-width: 100%;
    -moz-box-sizing: content-box!important;
    -webkit-box-sizing: content-box!important;
    box-sizing: content-box!important;
}
.aio-icon-img .img-icon {
    width: 1em;
    max-width: 100%!important;
    -webkit-border-radius: 0!important;
    border-radius: 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    display: block;
    font-size: inherit;
}
.comment-content img, .entry-content img {
    max-width: 100%;
    height: auto;
}

.second{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 750px;
    padding-left: 256px;
    padding-right: 161px;
}
@media screen and (max-width: 768px) {
    .second{
      display: none;   
      height: 1050px;
      background-color: yellow;
      justify-content: center;
      align-items: center;
   
    }   
}
.image_row{
    background-color: orange;
    /* display: flex; */
    flex-direction: row;
}
@media screen and (max-width: 768px) {
    .image_row{
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* width: 50%; */
    }
}
.second-image{
    font-size: 500px;
    height: 500px;
    background-color: blue;
    border-style: solid;
    border-color: #fff;
    border-width: 2px;
    padding: 4px;
    border-radius: 0px;
    display: inline-block;
    margin-top: 150px;
}