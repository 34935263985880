.floorplan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    /* background-color: red; */
}
@media screen and (max-width:768px) {
    .floorplan {
        /* background-color: orange; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 20px;
    }
}