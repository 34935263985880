.vc_row.vc_column-gap-10>.vc_column_container {
    padding: 5px;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_custom_1673186668667 {
    padding-right: 70px !important;
    padding-left: 70px !important;
}
.vc_column_container {
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 768px){
.vc_col-sm-12 {
    width: 100%;
}}
@media (min-width: 768px){
.vc_col-sm-1, .vc_col-sm-10, .vc_col-sm-11, .vc_col-sm-12, .vc_col-sm-2, .vc_col-sm-3, .vc_col-sm-4, .vc_col-sm-5, .vc_col-sm-6, .vc_col-sm-7, .vc_col-sm-8, .vc_col-sm-9 {
    float: left;
}}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_custom_1673093949495 {
    border-top-width: 2px !important;
    border-right-width: 2px !important;
    border-bottom-width: 2px !important;
    border-left-width: 2px !important;
    background-color: #ffffff !important;
    background-position: 0 0 !important;
    background-repeat: repeat !important;
    border-left-color: #50000e !important;
    border-left-style: solid !important;
    border-right-color: #50000e !important;
    border-right-style: solid !important;
    border-top-color: #50000e !important;
    border-top-style: solid !important;
    border-bottom-color: #50000e !important;
    border-bottom-style: solid !important;
    border-radius: 1px !important;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.aio-icon-img {
    display: inline-block;
    font-size: 48px;
    width: 1em;
    max-width: 100%;
    -moz-box-sizing: content-box!important;
    -webkit-box-sizing: content-box!important;
    box-sizing: content-box!important;
}
.aio-icon-img .img-icon {
    width: 1em;
    max-width: 100%!important;
    -webkit-border-radius: 0!important;
    border-radius: 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    display: block;
    font-size: inherit;
}
.comment-content img, .entry-content img {
    max-width: 100%;
    height: auto;
}
.wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid>li {
    margin-bottom: 0px!important;
}
b, strong {
    font-weight: 700;
}
.ubtn-ctn-center {
    display: block;
    text-align: center;
}
a.ubtn-link {
    display: inline-block;
    margin: 0;
    color: inherit;
    text-decoration: none;
    max-width: 100%;
}
.vc_column-inner::after {
    clear: both;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.vc_row.vc_column-gap-10>.vc_column_container {
    padding: 5px;
}
.upb_video_class, .wpb_column, .wpb_row {
    position: relative;
}
.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_custom_1674561452343 {
    margin-left: -60px !important;
}
.uvc-heading {
    text-align: center;
}
.uvc-heading-spacer {
    margin: 0 auto;
    padding: 0;
    position: relative;
    overflow: hidden;
}
.uvc-heading.ultimate-heading-303364ccd8c1f3118 .uvc-sub-heading {
    font-size: 18px;
    line-height: 33px;
}
.uvc-sub-heading {
    line-height: normal;
    margin: 0 auto;
}
::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
.vc_row:after, .vc_row:before {
    content: " ";
    display: table;
}
.vc_column_container>.vc_column-inner {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}
.vc_custom_1674561471346 {
    margin-left: 30px !important;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}
.ubtn-ctn-center {
    display: block;
    text-align: center;
}
a.ubtn-link {
    display: inline-block;
    margin: 0;
    color: inherit;
    text-decoration: none;
    max-width: 100%;
}
.vc_column-inner::after {
    clear: both;
}
.vc_column-inner::after, .vc_column-inner::before {
    content: " ";
    display: table;
}

.home_container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-left: 161px;
    padding-right: 161px;
    padding-top: 100px;
    flex-direction: column;
}
@media screen and (max-width: 768px) {
    .home_container {
        display: flex;
        position: relative;
        box-sizing: border-box;
        /* width: 100%; */
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        flex-direction: column;
    }
}
.inner_content{
    /* background-color: #50000e; */
    /* display: flex; */
    /* flex-direction: row; */
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;
}
@media screen and (max-width: 768px) {
    .inner_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        margin-top: 5px;
        margin-right: 50px;
    }
}
.text_container{
    /* background-color: red; */
    display: flex;
    /* border: 2px solid yellow; */
    /* width: 50%; */
}
@media screen and (max-width: 768px) {
.text_container{
    /* background-color: aquamarine; */
    /* display: flex; */
    border: 2px solid #374f5a;
    width: 35%;
    margin-top: 10px;
}
}
.button-container{
    /* background-color: blue; */
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    margin-left: 50px;
}
@media screen and (max-width: 768px) {
    .button-container{
        /* background-color: blue; */
        flex-direction: column;
        display: flex;
        align-items: center; 
        justify-content: center;
        margin-left: 0px;
        width: 45%;
    }
}
.text-style{
    margin-top: -12px;
    text-align: left;
    margin-left: 40px;
}
@media screen and (max-width: 768px) {
    .text-style{
        margin-left: 40px;
        text-align: left;
        width: 35%;
        margin-top: 20px;
    }
}
.btn-custom{
    width: 400px;
}
@media screen and (max-width: 768px) {
    .btn-custom{
        width: 350px;
        margin-right: 180px;
    }
}
.last_button{
    width: 50%;
    margin-right: 100px; 
    margin-top: 20px;  
    margin-left: 10px; 
}
@media screen and (max-width: 768px) {
    .last_button{
        /* background-color: #50000e; */
        margin-right: 500px;
        margin-left: 0px;
        margin-bottom: 20px;
        /* width: 10%; */
    }
}