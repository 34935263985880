/* @charset "utf-8"; @charset "utf-8"; @charset "UTF-8"; .aio-icon-component i,.align-icon i,.flip-box-wrap i,.info-circle-wrapper i,.smile-icon-timeline-wrap i,.smile_icon_list_wrap i,.stats-block i,.uavc-icons i,.uavc-list-icon i,.ubtn i,.ult-animation i,.ult-banner-block i,.ult-new-ib i,.ult-overlay i,.ult-vc-seperator i,.ult_countdown i,.ult_pricing_table_wrap i,.ultb3-box i,.ultimate-call-to-action i,.ultimate-google-trends i,.ultimate-map-wrapper i,.uvc-heading i,.uvc-type-wrap i {
  font-size: inherit;
  line-height: inherit
}

.aio-icon-component,.aio-icon-component *,.align-icon,.align-icon *,.flip-box-wrap,.flip-box-wrap *,.info-circle-wrapper,.info-circle-wrapper *,.smile-icon-timeline-wrap,.smile-icon-timeline-wrap *,.smile_icon_list_wrap,.smile_icon_list_wrap *,.stats-block,.stats-block *,.uavc-icons,.uavc-icons *,.uavc-list-icon,.uavc-list-icon *,.ubtn,.ubtn *,.ubtn-ctn-center,.ubtn-link,.ult-animation,.ult-animation *,.ult-banner-block,.ult-banner-block *,.ult-new-ib,.ult-new-ib *,.ult-overlay,.ult-overlay *,.ult_countdown,.ult_countdown *,.ult_pricing_table_wrap,.ult_pricing_table_wrap *,.ultimate-google-trends,.ultimate-google-trends *,.ultimate-map-wrapper,.ultimate-map-wrapper *,.uvc-heading,.uvc-heading * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.upb_video_class,.vc_row,.wpb_column,.wpb_row {
  position: relative
}

.aio-icon,.vc_row .aio-icon {
  display: inline-block;
  font-size: 32px;
  height: 2em;
  width: 2em;
  text-align: center;
  line-height: 2em;
  -moz-box-sizing: content-box!important;
  -webkit-box-sizing: content-box!important;
  box-sizing: content-box!important
}

.aio-icon.none {
  display: inline-block;
  font-size: 32px;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1em
}

.aio-icon.circle {
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.aio-icon.hexagon:before {
  content: "";
  position: absolute;
  top: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-bottom: 1em solid;
  border-bottom-color: inherit
}

.aio-icon.hexagon {
  position: relative;
  width: 3em;
  height: 1.5em;
  line-height: 1.5em;
  margin: 1.2em 0
}

.aio-icon.hexagon:after {
  content: "";
  position: absolute;
  bottom: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-top: 1em solid;
  border-top-color: inherit
}

.aio-icon-img.uavc-hexagon img {
  display: inline-block;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.aio-icon-img.uavc-hexagon {
  position: relative;
  width: 3em;
  height: 1.5em;
  line-height: 1.5em;
  margin-top: 1.1em;
  margin-bottom: 1.1em
}

.aio-icon-img.uavc-hexagon:after {
  content: "";
  position: absolute;
  bottom: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-top: 1em solid;
  border-top-color: inherit
}

.aio-icon-img.uavc-hexagon:before {
  content: "";
  position: absolute;
  top: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-bottom: 1em solid;
  border-bottom-color: inherit
}

.aio-icon-img {
  display: inline-block;
  font-size: 48px;
  width: 1em;
  max-width: 100%;
  -moz-box-sizing: content-box!important;
  -webkit-box-sizing: content-box!important;
  box-sizing: content-box!important
}

.aio-icon-img.uavc-circle {
  width: 1em;
  min-height: 1em;
  background: rgba(214,214,214,.44);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: .5em
}

.aio-icon-img.uavc-square {
  width: 1em;
  min-height: 1em;
  background: rgba(214,214,214,.44);
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: .5em
}

.aio-icon-img .img-icon {
  width: 1em;
  max-width: 100%!important;
  -webkit-border-radius: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  display: block;
  font-size: inherit
}

.aio-tooltip {
  display: inline-block;
  text-align: center;
  width: auto;
  max-width: 100%
}

.smile_icon_list {
  list-style: none!important;
  padding: 0!important;
  margin-left: 0!important;
  margin-right: 0!important;
  margin-bottom: 0!important
}

.smile_icon_list li.icon_list_item {
  padding: 0!important;
  list-style: none!important;
  clear: both!important;
  position: relative!important;
  min-height: 1em!important;
  margin: 0 0 30px!important;
  line-height: 100%;
  overflow: visible
}

.smile_icon_list li.icon_list_item:last-child {
  margin: 0!important
}

a.ulimate-info-list-link {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 9;
  width: 100%;
  height: 100%
}

.smile_icon_list li .icon_list_icon {
  font-size: 24px;
  width: 3em;
  height: 3em;
  line-height: 3em;
  text-align: center;
  float: left;
  background: #fff;
  z-index: 9;
  position: relative;
  -moz-box-sizing: content-box!important;
  -webkit-box-sizing: content-box!important;
  box-sizing: content-box!important
}

.smile_icon_list .list-img-icon {
  display: block;
  margin: 0 auto;
  max-height: 100%!important;
  max-width: 100%!important;
  -webkit-border-radius: 0!important;
  border-radius: 0!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  top: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.smile_icon_list li .icon_description h3 {
  font-size: 17px;
  line-height: 1.846153846;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  clear: none!important
}

.smile_icon_list li .icon_description h3 a {
  text-decoration: none
}

.smile_icon_list li .icon_description .info-list-heading {
  font-size: 17px;
  line-height: 1.846153846;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  clear: none!important
}

.smile_icon_list li .icon_description .info-list-heading a {
  text-decoration: none
}

.smile_icon_list li .icon_description p {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit!important;
  line-height: inherit;
  color: inherit!important
}

.smile_icon_list li .icon_description p:last-child {
  margin-bottom: 0
}

.smile_icon_list li .icon_description div.icon_description_text {
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: 13px;
  line-height: 18px
}

.smile_icon_list li .icon_description span.icon_description_text {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 14px;
  line-height: 1.714285714;
  display: block
}

.smile_icon_list li .icon_list_connector {
  height: 100%;
  position: absolute;
  top: .5em;
  z-index: 1
}

.info-circle-wrapper.info-circle-responsive .smile_icon_list li .icon_list_connector {
  top: 1em
}

.smile_icon_list li:last-child .icon_list_connector {
  display: none
}

.smile_icon_list.no_bg .icon_list_icon {
  border: 2px solid #0183cc;
  color: #0183cc
}

.smile_icon_list.square .icon_list_icon {
  border-color: inherit
}

.smile_icon_list.circle .icon_list_icon {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-color: inherit;
  overflow: hidden
}

.smile_icon_list.with_bg .icon_list_icon {
  background: #0183cc;
  color: #fff
}

.smile_icon_list.left li .icon_list_connector {
  left: .5em;
  border-right-width: 1px;
  border-right-style: dashed
}

.smile_icon_list.left li.ult-info-circle-img .icon_list_connector {
  left: .34em;
  top: .5em
}

.smile_icon_list.left li .icon_description {
  margin-left: 3em;
  padding-left: 25px;
  height: 3em;
  vertical-align: middle;
  display: table-cell
}

.smile_icon_list.right {
  text-align: right
}

.smile_icon_list.right li .icon_list_icon {
  float: right;
  position: relative
}

.smile_icon_list.right li .icon_list_connector {
  right: .5em;
  border-left-width: 1px;
  border-left-style: dashed
}

.smile_icon_list.right li .icon_description {
  margin-left: 3em;
  height: 3em;
  vertical-align: middle;
  display: table-cell
}

.smile_icon_list.right li .icon_description {
  margin-right: 3em;
  padding-right: 25px
}

.smile_icon_list.top,.smile_icon_list.top:after,.smile_icon_list.top:before {
  content: " ";
  display: table;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.smile_icon_list.top {
  display: block
}

.smile_icon_list.top li {
  display: table-cell;
  width: 1%;
  float: none;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.smile_icon_list.top li .icon_list_icon {
  margin: 0 auto;
  float: none
}

.smile_icon_list.top li .icon_description {
  text-align: center;
  margin: 15px;
  padding-bottom: 0
}

.smile_icon_list.top li .icon_list_connector {
  position: absolute;
  top: .5em;
  left: 50%;
  width: 100%;
  height: 10px;
  border-top-width: 1px;
  border-top-style: dashed;
  margin-left: 0
}

.info-wrapper .smile_icon_list li .icon_description .responsive-font-class * {
  font-size: 13px;
  line-height: 1.64em;
  color: inherit
}

@media only screen and (max-width: 768px) {
  .smile_icon_list_wrap .smile_icon_list.top {
      display:inline-block;
      width: 100%;
  }

  .smile_icon_list.top li.icon_list_item {
      display: block;
      width: 100%;
      float: left
  }

  .smile_icon_list.top li .icon_list_connector {
      display: none
  }
}

.hexagon li .icon_list_icon:before {
  content: "";
  position: absolute;
  top: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-bottom: 1em solid;
  border-bottom-color: inherit!important
}

.hexagon li .icon_list_icon {
  width: 3em!important;
  height: 1.5em!important;
  line-height: 1.5em!important;
  background: #0183cc;
  border-color: #0183cc;
  color: #fff;
  position: relative;
  top: 1em;
  overflow: inherit
}

.hexagon li .icon_list_icon:after {
  content: "";
  position: absolute;
  bottom: -1em;
  left: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-top: 1em solid;
  border-top-color: inherit!important
}

.smile_icon_list.top.hexagon .icon_list_icon {
  position: relative;
  margin-bottom: 2em
}

.smile_icon_list.top.hexagon .icon_list_connector {
  top: .55em
}

.full-width-content.wpb-js-composer .site-inner {
  overflow: visible!important
}

.smile_icon_list.circle {
  float: none;
  border: 0;
  width: auto;
  margin: 0;
  cursor: inherit
}

.ult-animation * {
  -webkit-backface-visibility: hidden
}

.ult-animation.ult-animate-viewport {
  -webkit-transition: opacity .9s linear;
  transition: opacity .9s linear
}

.uavc-list-icon ul {
  list-style: none!important;
  padding: 0!important;
  margin: 0 0 30px!important;
  position: relative!important
}

.uavc-list-icon ul:last-child {
  margin: 0!important
}

.uavc-list-icon li {
  padding: 0!important;
  margin: 0!important;
  list-style: none!important;
  clear: both!important;
  line-height: 1.6em;
  overflow: visible
}

.uavc-list-content {
  display: table;
  width: 100%;
  margin-bottom: 7px
}

.uavc-list-content .aio-icon {
  display: inline-block;
  margin: 0;
  padding: 0
}

.uavc-list-content span.uavc-list-desc {
  display: table-cell;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  width: 100%
}

.uavc-icons,.wpb_column .wpb_wrapper .uavc-icons {
  display: block;
  margin-bottom: 35px;
  line-height: 1
}

.uavc-icons-left.uavc-icons {
  text-align: left
}

.uavc-icons-center.uavc-icons {
  text-align: center
}

.uavc-icons-right.uavc-icons {
  text-align: right
}

.aio-icon i {
  line-height: inherit
}

.ultimate_google_map img {
  max-width: initial!important
}

.vc_element.element-ultimate_icons {
  float: left;
  padding-left: 75%
}

.vc_ultimate_icons a.vc_control-btn.vc_control-btn-append {
  margin-top: 20px;
  margin-left: 50%;
  z-index: 33000000
}

.smile_icon_list.left li .icon_description {
  line-height: 1em
}

.vc_element.element-info_list {
  padding-top: 20%;
  float: left;
  padding-left: 20%
}

.vc_element.element-ultimate_icon_list {
  margin-top: 20%;
  margin-left: 50%
}

.vc_ultimate_icon_list a.vc_control-btn.vc_control-btn-append {
  margin-left: 85%
}

.vc_element.element-icon_timeline {
  display: block;
  float: left;
  padding-left: 50%
}

.vc_element.element-ultimate_carousel {
  padding-top: 20%
}

.vc_element.element-ult_ihover {
  margin-top: 20%
}

.vc_element.element-ult_hotspot {
  margin-top: 20%
}

.aio-icon-description div,.aio-icon-description p,.ifb-flip-box-section-content div,.ifb-flip-box-section-content p,.info-circle-text div,.info-circle-text p,.timeline-header>div div,.timeline-header>div p,.uavc-list-desc div,.uavc-list-desc p,.ult-tooltipster-content div,.ult-tooltipster-content p,.ult_modal-body div,.ult_modal-body p,.ult_price_features div,.ult_price_features p,.ultimate-vticker div,.ultimate-vticker p,.uvc-ctaction-data,.uvc-ctaction-data div,.uvc-ctaction-data p,.uvc-sub-heading div,.uvc-sub-heading p {
  font-family: inherit!important;
  font-weight: inherit!important;
  font-size: inherit!important;
  font-style: inherit!important;
  color: inherit!important;
  line-height: inherit!important
}

.ultimate-ticker-prefix,.ultimate-ticker-suffix {
  font-size: inherit;
  line-height: inherit
}

.strip_highlight_text div,.strip_highlight_text p,.strip_main_text div,.strip_main_text p,.ubtn-text,.ulsb-strip>h4 div,.ulsb-strip>h4 p,span.middle-text div,span.middle-text p {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  color: inherit;
  line-height: inherit
}

a.ult_colorlink div,a.ult_colorlink p {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  line-height: inherit
}

li.ult_tab_li>a.ult_a span {
  color: inherit
}

[class*=x-icon-],[data-x-icon] {
  line-height: inherit
}

.info-wrapper.ult-adjust-bottom-margin,.smile_icon_list_wrap.ult-adjust-bottom-margin,.uavc-list-icon-wrapper.ult-adjust-bottom-margin,.ubtn-ctn-center,.ubtn-ctn-left,.ubtn-ctn-right,.ubtn-img-container,.ult-banner-block.ult-adjust-bottom-margin,.ult-modal-input-wrapper.ult-adjust-bottom-margin,.ult_dual_button.ult-adjust-bottom-margin,.ultb3-box.ult-adjust-bottom-margin,.ultimate-call-to-action.ult-adjust-bottom-margin,.ultimate-google-trends.ult-adjust-bottom-margin,.ultimate-map-wrapper.ult-adjust-bottom-margin,.uvc-type-wrap.ult-adjust-bottom-margin,html .ult-ih-container.ult-adjust-bottom-margin,html .ult_exp_section_layer.ult-adjust-bottom-margin,html .ult_hotspot_container.ult-adjust-bottom-margin,html .ult_main_cl.ult-adjust-bottom-margin {
  margin-bottom: 35px
}

.ultimate-map-wrapper {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

html .vc_col-has-fill a.ubtn-link.ult-adjust-bottom-margin .ubtn.ult-adjust-bottom-margin {
  margin-bottom: 0
}

.smile_icon_list.right>li {
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right
}

.smile_icon_list.right .icon_list_item {
  display: block
}

.vc_row-no-padding .ult-carousel-wrapper .slick-next {
  right: 0
}

.vc_row-no-padding .ult-carousel-wrapper .slick-prev {
  left: 0
}

.ult-carousel-wrapper {
  margin-bottom: 35px;
  padding-bottom: 20px
}

.ult-carousel-wrapper {
  visibility: hidden
}

.slick-initialized {
  visibility: visible
}

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  -webkit-tap-highlight-color: transparent
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-loading .slick-list {
  background: #fff url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/ajax-loader.gif) center center no-repeat
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slide,.slick-slide img,.slick-slider .slick-list,.slick-track {
  -webkit-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom:1}

.slick-track:after,.slick-track:before {
  content: "";
  display: table
}

.slick-track:after {
  clear: both
}

.slick-loading .slick-track {
  visibility: hidden
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-loading .slick-slide {
  visibility: hidden
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

@font-face {
  font-family: slick;
  src: url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/fonts/slick.eot);
  src: url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/fonts/slick.eot?#iefix) format("embedded-opentype"),url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/fonts/slick.woff) format("woff"),url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/fonts/slick.ttf) format("truetype"),url(https://gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/min-css/fonts/slick.svg#slick) format("svg");
  font-weight: 400;
  font-style: normal
}

.slick-next,.slick-prev {
  position: absolute;
  display: block;
  height: 1em;
  width: 1em;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: 0
}

.slick-next:active,.slick-next:focus,.slick-next:hover,.slick-prev:active,.slick-prev:focus,.slick-prev:hover {
  outline: 0;
  background: 0 0;
  color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before {
  opacity: 1
}

.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before {
  opacity: .25
}

.slick-next:before,.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.slick-prev {
  left: -15px
}

[dir=rtl] .slick-prev {
  left: auto;
  right: -15px;
  -webkit-tranform: rotate(-180deg);
  -moz-tranform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.slick-next {
  right: -15px
}

[dir=rtl] .slick-next {
  left: -15px;
  right: auto;
  -webkit-tranform: rotate(-180deg);
  -moz-tranform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.ult-carousel-wrapper.ult_full_width button.slick-next {
  right: 25px
}

.ult-carousel-wrapper.ult_full_width button.slick-prev {
  left: 25px
}

[dir=rtl] .ult-carousel-wrapper.ult_full_width button.slick-next {
  left: 25px
}

[dir=rtl] .ult-carousel-wrapper.ult_full_width button.slick-prev {
  right: 25px
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer
}

.slick-dots li button {
  border: 0;
  background: 0 0;
  display: block;
  height: 20px;
  width: 20px;
  outline: 0;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer
}

.slick-dots li button:focus,.slick-dots li button:hover {
  outline: 0
}

.slick-dots li button:focus:before,.slick-dots li button:hover:before {
  opacity: 1
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.slick-dots li.slick-active button:before {
  color: #000;
  opacity: .75
}

.ult-carousel-wrapper .slick-dots li i {
  opacity: .5;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.ult-carousel-wrapper .slick-dots li:hover i {
  opacity: 1
}

.ult-carousel-wrapper .slick-dots li.slick-active i {
  opacity: 1
}

button.slick-next:active,button.slick-prev:active {
  background: initial;
  border: initial;
  padding: initial
}

.ult-item-wrap>.wpb_button,.ult-item-wrap>.wpb_content_element,.ult-item-wrap>.wpb_row,.ult-item-wrap>div,.ult-item-wrap>ul.wpb_thumbnails-fluid>li {
  margin-bottom: 0!important
}

ul.slick-dots {
  margin: 0;
  padding: 10px 0
}

.slick-dots {
  bottom: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%)
}

.ult-carousel-wrapper button {
  z-index: 1
}

.ult-carousel-wrapper button.circle-bg,.ult-carousel-wrapper button.circle-border {
  -webkit-border-radius: 100%;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.7em
}

.ult-carousel-wrapper button.square-bg,.ult-carousel-wrapper button.square-border {
  -webkit-border-radius: 0!important;
  border-radius: 0!important;
  width: 1.5em;
  height: 1.5em
}

.ult-carousel-wrapper.ult_vertical {
  padding: 50px 0
}

.ult-carousel-wrapper.ult_vertical .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.ult-carousel-wrapper.ult_vertical button.slick-next,.ult-carousel-wrapper.ult_vertical button.slick-prev {
  left: 50%;
  margin: 0;
  -webkit-transform: translateX(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) rotate(90deg);
  transform: translateX(-50%) rotate(90deg)
}

.ult-carousel-wrapper.ult_vertical button.slick-prev {
  top: -45px
}

.ult-carousel-wrapper.ult_vertical button.slick-next {
  top: auto;
  bottom: -40px
}

.ult-carousel-wrapper.ult_vertical ul.slick-dots {
  z-index: 99;
  bottom: -30px
}

button.slick-next.circle-border i,button.slick-prev.circle-border i {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

@font-face {
  font-family: 'ult-silk';
  src: url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/css/fonts/ult-silk.eot);
  src: url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/css/fonts/ult-silk.eot) format('embedded-opentype'), url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/css/fonts/ult-silk.woff) format('woff'), url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/css/fonts/ult-silk.ttf) format('truetype'), url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/css/fonts/ult-silk.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ultsl-"], [class*=" ultsl-"] {
  font-family: 'ult-silk';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ultsl-arrow-right:before {
  content: "\e600";
}

.ultsl-arrow-left:before {
  content: "\e601";
}

.ultsl-arrow-right2:before {
  content: "\e602";
}

.ultsl-arrow-left2:before {
  content: "\e603";
}

.ultsl-arrow-right3:before {
  content: "\e604";
}

.ultsl-arrow-left3:before {
  content: "\e605";
}

.ultsl-checkbox-unchecked:before {
  content: "\e606";
}

.ultsl-checkbox-partial:before {
  content: "\e607";
}

.ultsl-radio-checked:before {
  content: "\e608";
}

.ultsl-radio-unchecked:before {
  content: "\e609";
}

.ultsl-record:before {
  content: "\e60a";
}

.ultsl-stop:before {
  content: "\e60b";
}

.ultsl-arrow-left4:before {
  content: "\e60c";
}

.ultsl-arrow-right4:before {
  content: "\e60d";
}

.ultsl-arrow-left5:before {
  content: "\e60e";
}

.ultsl-arrow-right5:before {
  content: "\e60f";
}

.ultsl-arrow-left6:before {
  content: "\e610";
}

.ultsl-arrow-right6:before {
  content: "\e611";
}

.wp-admin span#animate-me {
  display: inline-block;
  position: relative
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s
}

@-webkit-keyframes bounce {
  0%,100%,20%,50%,80% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px)
  }
}

@keyframes bounce {
  0%,100%,20%,50%,80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  40% {
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px)
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce
}

@-webkit-keyframes flash {
  0%,100%,50% {
      opacity: 1
  }

  25%,75% {
      opacity: 0
  }
}

@keyframes flash {
  0%,100%,50% {
      opacity: 1
  }

  25%,75% {
      opacity: 0
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash
}

@-webkit-keyframes pulse {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes pulse {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  50% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse
}

@-webkit-keyframes rubberBand {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  30% {
      -webkit-transform: scaleX(1.25) scaleY(.75);
      transform: scaleX(1.25) scaleY(.75)
  }

  40% {
      -webkit-transform: scaleX(.75) scaleY(1.25);
      transform: scaleX(.75) scaleY(1.25)
  }

  60% {
      -webkit-transform: scaleX(1.15) scaleY(.85);
      transform: scaleX(1.15) scaleY(.85)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes rubberBand {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  30% {
      -webkit-transform: scaleX(1.25) scaleY(.75);
      -ms-transform: scaleX(1.25) scaleY(.75);
      transform: scaleX(1.25) scaleY(.75)
  }

  40% {
      -webkit-transform: scaleX(.75) scaleY(1.25);
      -ms-transform: scaleX(.75) scaleY(1.25);
      transform: scaleX(.75) scaleY(1.25)
  }

  60% {
      -webkit-transform: scaleX(1.15) scaleY(.85);
      -ms-transform: scaleX(1.15) scaleY(.85);
      transform: scaleX(1.15) scaleY(.85)
  }

  100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand
}

@-webkit-keyframes shake {
  0%,100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px)
  }
}

@keyframes shake {
  0%,100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px)
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake
}

@-webkit-keyframes swing {
  20% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg)
  }

  40% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }

  60% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg)
  }

  80% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg)
  }

  100% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }
}

@keyframes swing {
  20% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg)
  }

  40% {
      -webkit-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }

  60% {
      -webkit-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      transform: rotate(5deg)
  }

  80% {
      -webkit-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      transform: rotate(-5deg)
  }

  100% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0)
  }
}

.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing
}

@-webkit-keyframes tada {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  10%,20% {
      -webkit-transform: scale(.9) rotate(-3deg);
      transform: scale(.9) rotate(-3deg)
  }

  30%,50%,70%,90% {
      -webkit-transform: scale(1.1) rotate(3deg);
      transform: scale(1.1) rotate(3deg)
  }

  40%,60%,80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
      transform: scale(1.1) rotate(-3deg)
  }

  100% {
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0)
  }
}

@keyframes tada {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  10%,20% {
      -webkit-transform: scale(.9) rotate(-3deg);
      -ms-transform: scale(.9) rotate(-3deg);
      transform: scale(.9) rotate(-3deg)
  }

  30%,50%,70%,90% {
      -webkit-transform: scale(1.1) rotate(3deg);
      -ms-transform: scale(1.1) rotate(3deg);
      transform: scale(1.1) rotate(3deg)
  }

  40%,60%,80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
      -ms-transform: scale(1.1) rotate(-3deg);
      transform: scale(1.1) rotate(-3deg)
  }

  100% {
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0)
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada
}

@-webkit-keyframes wobble {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  15% {
      -webkit-transform: translateX(-25%) rotate(-5deg);
      transform: translateX(-25%) rotate(-5deg)
  }

  30% {
      -webkit-transform: translateX(20%) rotate(3deg);
      transform: translateX(20%) rotate(3deg)
  }

  45% {
      -webkit-transform: translateX(-15%) rotate(-3deg);
      transform: translateX(-15%) rotate(-3deg)
  }

  60% {
      -webkit-transform: translateX(10%) rotate(2deg);
      transform: translateX(10%) rotate(2deg)
  }

  75% {
      -webkit-transform: translateX(-5%) rotate(-1deg);
      transform: translateX(-5%) rotate(-1deg)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes wobble {
  0% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  15% {
      -webkit-transform: translateX(-25%) rotate(-5deg);
      -ms-transform: translateX(-25%) rotate(-5deg);
      transform: translateX(-25%) rotate(-5deg)
  }

  30% {
      -webkit-transform: translateX(20%) rotate(3deg);
      -ms-transform: translateX(20%) rotate(3deg);
      transform: translateX(20%) rotate(3deg)
  }

  45% {
      -webkit-transform: translateX(-15%) rotate(-3deg);
      -ms-transform: translateX(-15%) rotate(-3deg);
      transform: translateX(-15%) rotate(-3deg)
  }

  60% {
      -webkit-transform: translateX(10%) rotate(2deg);
      -ms-transform: translateX(10%) rotate(2deg);
      transform: translateX(10%) rotate(2deg)
  }

  75% {
      -webkit-transform: translateX(-5%) rotate(-1deg);
      -ms-transform: translateX(-5%) rotate(-1deg);
      transform: translateX(-5%) rotate(-1deg)
  }

  100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble
}

@-webkit-keyframes bounceIn {
  0% {
      opacity: 0;
      -webkit-transform: scale(.3);
      transform: scale(.3)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      transform: scale(1.05)
  }

  70% {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }

  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes bounceIn {
  0% {
      opacity: 0;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05)
  }

  70% {
      -webkit-transform: scale(.9);
      -ms-transform: scale(.9);
      transform: scale(.9)
  }

  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn
}

@-webkit-keyframes bounceInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(30px);
      transform: translateY(30px)
  }

  80% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes bounceInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px)
  }

  80% {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown
}

@-webkit-keyframes bounceInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(30px);
      transform: translateX(30px)
  }

  80% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes bounceInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px)
  }

  80% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft
}

@-webkit-keyframes bounceInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(-30px);
      transform: translateX(-30px)
  }

  80% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes bounceInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateX(-30px);
      -ms-transform: translateX(-30px);
      transform: translateX(-30px)
  }

  80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px)
  }

  100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight
}

@-webkit-keyframes bounceInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  80% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes bounceInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  60% {
      opacity: 1;
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  80% {
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px)
  }

  100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp
}

@-webkit-keyframes bounceOut {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  25% {
      -webkit-transform: scale(.95);
      transform: scale(.95)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.3);
      transform: scale(.3)
  }
}

@keyframes bounceOut {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  25% {
      -webkit-transform: scale(.95);
      -ms-transform: scale(.95);
      transform: scale(.95)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3)
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut
}

@-webkit-keyframes bounceOutDown {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

@keyframes bounceOutDown {
  0% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown
}

@-webkit-keyframes bounceOutLeft {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      transform: translateX(20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

@keyframes bounceOutLeft {
  0% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft
}

@-webkit-keyframes bounceOutRight {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

@keyframes bounceOutRight {
  0% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight
}

@-webkit-keyframes bounceOutUp {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateY(20px);
      transform: translateY(20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

@keyframes bounceOutUp {
  0% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  20% {
      opacity: 1;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp
}

@-webkit-keyframes fadeIn {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0
  }

  100% {
      opacity: 1
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown
}

@-webkit-keyframes fadeInDownBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeInDownBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig
}

@-webkit-keyframes fadeInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

@-webkit-keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeInLeftBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig
}

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}

@-webkit-keyframes fadeInRightBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes fadeInRightBig {
  0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig
}

@-webkit-keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

@-webkit-keyframes fadeInUpBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes fadeInUpBig {
  0% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig
}

@-webkit-keyframes fadeOut {
  0% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1
  }

  100% {
      opacity: 0
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@-webkit-keyframes fadeOutDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px)
  }
}

@keyframes fadeOutDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px)
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown
}

@-webkit-keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

@keyframes fadeOutDownBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig
}

@-webkit-keyframes fadeOutLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px)
  }
}

@keyframes fadeOutLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px)
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

@keyframes fadeOutLeftBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig
}

@-webkit-keyframes fadeOutRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px)
  }
}

@keyframes fadeOutRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px)
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight
}

@-webkit-keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

@keyframes fadeOutRightBig {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig
}

@-webkit-keyframes fadeOutUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px)
  }
}

@keyframes fadeOutUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px)
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp
}

@-webkit-keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

@keyframes fadeOutUpBig {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig
}

@-webkit-keyframes flip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

@keyframes flip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip
}

@-webkit-keyframes flipInX {
  0% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg);
      opacity: 0
  }

  40% {
      -webkit-transform: perspective(400px) rotateX(-10deg);
      transform: perspective(400px) rotateX(-10deg)
  }

  70% {
      -webkit-transform: perspective(400px) rotateX(10deg);
      transform: perspective(400px) rotateX(10deg)
  }

  100% {
      -webkit-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateX(0);
      opacity: 1
  }
}

@keyframes flipInX {
  0% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      -ms-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg);
      opacity: 0
  }

  40% {
      -webkit-transform: perspective(400px) rotateX(-10deg);
      -ms-transform: perspective(400px) rotateX(-10deg);
      transform: perspective(400px) rotateX(-10deg)
  }

  70% {
      -webkit-transform: perspective(400px) rotateX(10deg);
      -ms-transform: perspective(400px) rotateX(10deg);
      transform: perspective(400px) rotateX(10deg)
  }

  100% {
      -webkit-transform: perspective(400px) rotateX(0);
      -ms-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateX(0);
      opacity: 1
  }
}

.flipInX {
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible!important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX
}

@-webkit-keyframes flipInY {
  0% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg);
      opacity: 0
  }

  40% {
      -webkit-transform: perspective(400px) rotateY(-10deg);
      transform: perspective(400px) rotateY(-10deg)
  }

  70% {
      -webkit-transform: perspective(400px) rotateY(10deg);
      transform: perspective(400px) rotateY(10deg)
  }

  100% {
      -webkit-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0);
      opacity: 1
  }
}

@keyframes flipInY {
  0% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      -ms-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg);
      opacity: 0
  }

  40% {
      -webkit-transform: perspective(400px) rotateY(-10deg);
      -ms-transform: perspective(400px) rotateY(-10deg);
      transform: perspective(400px) rotateY(-10deg)
  }

  70% {
      -webkit-transform: perspective(400px) rotateY(10deg);
      -ms-transform: perspective(400px) rotateY(10deg);
      transform: perspective(400px) rotateY(10deg)
  }

  100% {
      -webkit-transform: perspective(400px) rotateY(0);
      -ms-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0);
      opacity: 1
  }
}

.flipInY {
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible!important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY
}

@-webkit-keyframes flipOutX {
  0% {
      -webkit-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateX(0);
      opacity: 1
  }

  100% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg);
      opacity: 0
  }
}

@keyframes flipOutX {
  0% {
      -webkit-transform: perspective(400px) rotateX(0);
      -ms-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateX(0);
      opacity: 1
  }

  100% {
      -webkit-transform: perspective(400px) rotateX(90deg);
      -ms-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg);
      opacity: 0
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible!important
}

@-webkit-keyframes flipOutY {
  0% {
      -webkit-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0);
      opacity: 1
  }

  100% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg);
      opacity: 0
  }
}

@keyframes flipOutY {
  0% {
      -webkit-transform: perspective(400px) rotateY(0);
      -ms-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0);
      opacity: 1
  }

  100% {
      -webkit-transform: perspective(400px) rotateY(90deg);
      -ms-transform: perspective(400px) rotateY(90deg);
      transform: perspective(400px) rotateY(90deg);
      opacity: 0
  }
}

.flipOutY {
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible!important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY
}

@-webkit-keyframes lightSpeedIn {
  0% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      transform: translateX(100%) skewX(-30deg);
      opacity: 0
  }

  60% {
      -webkit-transform: translateX(-20%) skewX(30deg);
      transform: translateX(-20%) skewX(30deg);
      opacity: 1
  }

  80% {
      -webkit-transform: translateX(0) skewX(-15deg);
      transform: translateX(0) skewX(-15deg);
      opacity: 1
  }

  100% {
      -webkit-transform: translateX(0) skewX(0);
      transform: translateX(0) skewX(0);
      opacity: 1
  }
}

@keyframes lightSpeedIn {
  0% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      -ms-transform: translateX(100%) skewX(-30deg);
      transform: translateX(100%) skewX(-30deg);
      opacity: 0
  }

  60% {
      -webkit-transform: translateX(-20%) skewX(30deg);
      -ms-transform: translateX(-20%) skewX(30deg);
      transform: translateX(-20%) skewX(30deg);
      opacity: 1
  }

  80% {
      -webkit-transform: translateX(0) skewX(-15deg);
      -ms-transform: translateX(0) skewX(-15deg);
      transform: translateX(0) skewX(-15deg);
      opacity: 1
  }

  100% {
      -webkit-transform: translateX(0) skewX(0);
      -ms-transform: translateX(0) skewX(0);
      transform: translateX(0) skewX(0);
      opacity: 1
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out
}

@-webkit-keyframes lightSpeedOut {
  0% {
      -webkit-transform: translateX(0) skewX(0);
      transform: translateX(0) skewX(0);
      opacity: 1
  }

  100% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      transform: translateX(100%) skewX(-30deg);
      opacity: 0
  }
}

@keyframes lightSpeedOut {
  0% {
      -webkit-transform: translateX(0) skewX(0);
      -ms-transform: translateX(0) skewX(0);
      transform: translateX(0) skewX(0);
      opacity: 1
  }

  100% {
      -webkit-transform: translateX(100%) skewX(-30deg);
      -ms-transform: translateX(100%) skewX(-30deg);
      transform: translateX(100%) skewX(-30deg);
      opacity: 0
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in
}

@-webkit-keyframes rotateIn {
  0% {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(-200deg);
      transform: rotate(-200deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

@keyframes rotateIn {
  0% {
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(-200deg);
      -ms-transform: rotate(-200deg);
      transform: rotate(-200deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn
}

@-webkit-keyframes rotateInDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

@keyframes rotateInDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft
}

@-webkit-keyframes rotateInDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

@keyframes rotateInDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight
}

@-webkit-keyframes rotateInUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

@keyframes rotateInUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft
}

@-webkit-keyframes rotateInUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

@keyframes rotateInUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }

  100% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight
}

@-webkit-keyframes rotateOut {
  0% {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(200deg);
      transform: rotate(200deg);
      opacity: 0
  }
}

@keyframes rotateOut {
  0% {
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: rotate(200deg);
      -ms-transform: rotate(200deg);
      transform: rotate(200deg);
      opacity: 0
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }
}

@keyframes rotateOutDownLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft
}

@-webkit-keyframes rotateOutDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }
}

@keyframes rotateOutDownRight {
  0% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }
}

@keyframes rotateOutUpLeft {
  0% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
      transform-origin: left bottom;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft
}

@-webkit-keyframes rotateOutUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }
}

@keyframes rotateOutUpRight {
  0% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      opacity: 1
  }

  100% {
      -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
      transform-origin: right bottom;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      opacity: 0
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight
}

@-webkit-keyframes slideInDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes slideInDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown
}

@-webkit-keyframes slideInLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes slideInLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft
}

@-webkit-keyframes slideInRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes slideInRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight
}

@-webkit-keyframes slideOutLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

@keyframes slideOutLeft {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(-2000px);
      -ms-transform: translateX(-2000px);
      transform: translateX(-2000px)
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft
}

@-webkit-keyframes slideOutRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

@keyframes slideOutRight {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(2000px);
      -ms-transform: translateX(2000px);
      transform: translateX(2000px)
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight
}

@-webkit-keyframes slideOutUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

@keyframes slideOutUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(-2000px);
      -ms-transform: translateY(-2000px);
      transform: translateY(-2000px)
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp
}

@-webkit-keyframes slideInUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes slideInUp {
  0% {
      opacity: 1;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

@keyframes slideOutDown {
  0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(2000px);
      -ms-transform: translateY(2000px);
      transform: translateY(2000px)
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown
}

@-webkit-keyframes hinge {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  20%,60% {
      -webkit-transform: rotate(80deg);
      transform: rotate(80deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  40% {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  80% {
      -webkit-transform: rotate(60deg) translateY(0);
      transform: rotate(60deg) translateY(0);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1
  }

  100% {
      -webkit-transform: translateY(700px);
      transform: translateY(700px);
      opacity: 0
  }
}

@keyframes hinge {
  0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  20%,60% {
      -webkit-transform: rotate(80deg);
      -ms-transform: rotate(80deg);
      transform: rotate(80deg);
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  40% {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg);
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  80% {
      -webkit-transform: rotate(60deg) translateY(0);
      -ms-transform: rotate(60deg) translateY(0);
      transform: rotate(60deg) translateY(0);
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      opacity: 1
  }

  100% {
      -webkit-transform: translateY(700px);
      -ms-transform: translateY(700px);
      transform: translateY(700px);
      opacity: 0
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge
}

@-webkit-keyframes rollIn {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100%) rotate(-120deg);
      transform: translateX(-100%) rotate(-120deg)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0)
  }
}

@keyframes rollIn {
  0% {
      opacity: 0;
      -webkit-transform: translateX(-100%) rotate(-120deg);
      -ms-transform: translateX(-100%) rotate(-120deg);
      transform: translateX(-100%) rotate(-120deg)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0) rotate(0);
      -ms-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0)
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn
}

@-webkit-keyframes rollOut {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(100%) rotate(120deg);
      transform: translateX(100%) rotate(120deg)
  }
}

@keyframes rollOut {
  0% {
      opacity: 1;
      -webkit-transform: translateX(0) rotate(0);
      -ms-transform: translateX(0) rotate(0);
      transform: translateX(0) rotate(0)
  }

  100% {
      opacity: 0;
      -webkit-transform: translateX(100%) rotate(120deg);
      -ms-transform: translateX(100%) rotate(120deg);
      transform: translateX(100%) rotate(120deg)
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut
}

@-webkit-keyframes zoomIn {
  0% {
      opacity: 0;
      -webkit-transform: scale(.3);
      transform: scale(.3)
  }

  50% {
      opacity: 1
  }
}

@keyframes zoomIn {
  0% {
      opacity: 0;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3)
  }

  50% {
      opacity: 1
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn
}

@-webkit-keyframes zoomInDown {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(-2000px);
      transform: scale(.1) translateY(-2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(60px);
      transform: scale(.475) translateY(60px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

@keyframes zoomInDown {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(-2000px);
      -ms-transform: scale(.1) translateY(-2000px);
      transform: scale(.1) translateY(-2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(60px);
      -ms-transform: scale(.475) translateY(60px);
      transform: scale(.475) translateY(60px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown
}

@-webkit-keyframes zoomInLeft {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(-2000px);
      transform: scale(.1) translateX(-2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(48px);
      transform: scale(.475) translateX(48px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

@keyframes zoomInLeft {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(-2000px);
      -ms-transform: scale(.1) translateX(-2000px);
      transform: scale(.1) translateX(-2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(48px);
      -ms-transform: scale(.475) translateX(48px);
      transform: scale(.475) translateX(48px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft
}

@-webkit-keyframes zoomInRight {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(2000px);
      transform: scale(.1) translateX(2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(-48px);
      transform: scale(.475) translateX(-48px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

@keyframes zoomInRight {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(2000px);
      -ms-transform: scale(.1) translateX(2000px);
      transform: scale(.1) translateX(2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(-48px);
      -ms-transform: scale(.475) translateX(-48px);
      transform: scale(.475) translateX(-48px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight
}

@-webkit-keyframes zoomInUp {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(2000px);
      transform: scale(.1) translateY(2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(-60px);
      transform: scale(.475) translateY(-60px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

@keyframes zoomInUp {
  0% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(2000px);
      -ms-transform: scale(.1) translateY(2000px);
      transform: scale(.1) translateY(2000px);
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out
  }

  60% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(-60px);
      -ms-transform: scale(.475) translateY(-60px);
      transform: scale(.475) translateY(-60px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp
}

@-webkit-keyframes zoomOut {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  50% {
      opacity: 0;
      -webkit-transform: scale(.3);
      transform: scale(.3)
  }

  100% {
      opacity: 0
  }
}

@keyframes zoomOut {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  50% {
      opacity: 0;
      -webkit-transform: scale(.3);
      -ms-transform: scale(.3);
      transform: scale(.3)
  }

  100% {
      opacity: 0
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut
}

@-webkit-keyframes zoomOutDown {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(-60px);
      transform: scale(.475) translateY(-60px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(2000px);
      transform: scale(.1) translateY(2000px);
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom
  }
}

@keyframes zoomOutDown {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(-60px);
      -ms-transform: scale(.475) translateY(-60px);
      transform: scale(.475) translateY(-60px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(2000px);
      -ms-transform: scale(.1) translateY(2000px);
      transform: scale(.1) translateY(2000px);
      -webkit-transform-origin: center bottom;
      -ms-transform-origin: center bottom;
      transform-origin: center bottom
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown
}

@-webkit-keyframes zoomOutLeft {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(42px);
      transform: scale(.475) translateX(42px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(-2000px);
      transform: scale(.1) translateX(-2000px);
      -webkit-transform-origin: left center;
      transform-origin: left center
  }
}

@keyframes zoomOutLeft {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(42px);
      -ms-transform: scale(.475) translateX(42px);
      transform: scale(.475) translateX(42px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(-2000px);
      -ms-transform: scale(.1) translateX(-2000px);
      transform: scale(.1) translateX(-2000px);
      -webkit-transform-origin: left center;
      -ms-transform-origin: left center;
      transform-origin: left center
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft
}

@-webkit-keyframes zoomOutRight {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(-42px);
      transform: scale(.475) translateX(-42px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(2000px);
      transform: scale(.1) translateX(2000px);
      -webkit-transform-origin: right center;
      transform-origin: right center
  }
}

@keyframes zoomOutRight {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateX(-42px);
      -ms-transform: scale(.475) translateX(-42px);
      transform: scale(.475) translateX(-42px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateX(2000px);
      -ms-transform: scale(.1) translateX(2000px);
      transform: scale(.1) translateX(2000px);
      -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
      transform-origin: right center
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight
}

@-webkit-keyframes zoomOutUp {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(60px);
      transform: scale(.475) translateY(60px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(-2000px);
      transform: scale(.1) translateY(-2000px);
      -webkit-transform-origin: center top;
      transform-origin: center top
  }
}

@keyframes zoomOutUp {
  40% {
      opacity: 1;
      -webkit-transform: scale(.475) translateY(60px);
      -ms-transform: scale(.475) translateY(60px);
      transform: scale(.475) translateY(60px);
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(.1) translateY(-2000px);
      -ms-transform: scale(.1) translateY(-2000px);
      transform: scale(.1) translateY(-2000px);
      -webkit-transform-origin: center top;
      -ms-transform-origin: center top;
      transform-origin: center top
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp
}

.InfiniteBounce,.InfiniteDangle,.InfiniteFlash,.InfiniteHorizontalFlip,.InfiniteHorizontalScaleFlip,.InfiniteHorizontalShake,.InfinitePulse,.InfiniteRotate,.InfiniteRotateCounter,.InfiniteRubberBand,.InfiniteSwing,.InfiniteTADA,.InfiniteVericalFlip,.InfiniteVericalShake,.InfiniteVerticalScaleFlip {
  -webkit-animation-iteration-count: infinite!important;
  animation-iteration-count: infinite!important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

@-webkit-keyframes InfiniteRotate {
  from {
      -webkit-transform: rotate(0)
  }

  to {
      -webkit-transform: rotate(359deg)
  }
}

@keyframes InfiniteRotate {
  from {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

.InfiniteRotate {
  -webkit-animation: InfiniteRotate;
  animation: InfiniteRotate
}

@-webkit-keyframes InfiniteRotateCounter {
  from {
      -webkit-transform: rotate(359deg)
  }

  to {
      -webkit-transform: rotate(0)
  }
}

@keyframes InfiniteRotateCounter {
  from {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }

  to {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }
}

.InfiniteRotateCounter {
  -webkit-animation: InfiniteRotateCounter;
  animation: InfiniteRotateCounter
}

@-webkit-keyframes InfiniteDangle {
  0%,100% {
      -webkit-transform: rotate(-4deg)
  }

  50% {
      -webkit-transform: rotate(4deg)
  }
}

@keyframes InfiniteDangle {
  0%,100% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg)
  }

  50% {
      -webkit-transform: rotate(4deg);
      transform: rotate(4deg)
  }
}

.InfiniteDangle {
  -webkit-animation: InfiniteDangle;
  animation: InfiniteDangle
}

@-webkit-keyframes InfinitePulse {
  0%,100% {
      -webkit-transform: scale(1)
  }

  50% {
      -webkit-transform: scale(.85)
  }
}

@keyframes InfinitePulse {
  0%,100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  50% {
      -webkit-transform: scale(.85);
      transform: scale(.85)
  }
}

.InfinitePulse {
  -webkit-animation: InfinitePulse;
  animation: InfinitePulse;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center
}

@-webkit-keyframes InfiniteHorizontalShake {
  0%,100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateX(10px);
      transform: translateX(10px)
  }
}

@keyframes InfiniteHorizontalShake {
  0%,100% {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      transform: translateX(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px)
  }
}

.InfiniteHorizontalShake {
  -webkit-animation-name: InfiniteHorizontalShake;
  animation-name: InfiniteHorizontalShake
}

@-webkit-keyframes InfiniteBounce {
  0%,100%,20%,50%,80% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px)
  }
}

@keyframes InfiniteBounce {
  0%,100%,20%,50%,80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  40% {
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px)
  }
}

.InfiniteBounce {
  -webkit-animation-name: InfiniteBounce;
  animation-name: InfiniteBounce
}

@-webkit-keyframes InfiniteFlash {
  0%,100%,50% {
      opacity: 1
  }

  25%,75% {
      opacity: 0
  }
}

@keyframes InfiniteFlash {
  0%,100%,50% {
      opacity: 1
  }

  25%,75% {
      opacity: 0
  }
}

.InfiniteFlash {
  -webkit-animation-name: InfiniteFlash;
  animation-name: InfiniteFlash
}

@-webkit-keyframes InfiniteTADA {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  10%,20% {
      -webkit-transform: scale(.9) rotate(-3deg);
      transform: scale(.9) rotate(-3deg)
  }

  30%,50%,70%,90% {
      -webkit-transform: scale(1.1) rotate(3deg);
      transform: scale(1.1) rotate(3deg)
  }

  40%,60%,80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
      transform: scale(1.1) rotate(-3deg)
  }

  100% {
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0)
  }
}

@keyframes InfiniteTADA {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  10%,20% {
      -webkit-transform: scale(.9) rotate(-3deg);
      -ms-transform: scale(.9) rotate(-3deg);
      transform: scale(.9) rotate(-3deg)
  }

  30%,50%,70%,90% {
      -webkit-transform: scale(1.1) rotate(3deg);
      -ms-transform: scale(1.1) rotate(3deg);
      transform: scale(1.1) rotate(3deg)
  }

  40%,60%,80% {
      -webkit-transform: scale(1.1) rotate(-3deg);
      -ms-transform: scale(1.1) rotate(-3deg);
      transform: scale(1.1) rotate(-3deg)
  }

  100% {
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0)
  }
}

.InfiniteTADA {
  -webkit-animation-name: InfiniteTADA;
  animation-name: InfiniteTADA
}

@-webkit-keyframes InfiniteSwing {
  20% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg)
  }

  40% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }

  60% {
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg)
  }

  80% {
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg)
  }

  100% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }
}

@keyframes InfiniteSwing {
  20% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg)
  }

  40% {
      -webkit-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }

  60% {
      -webkit-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      transform: rotate(5deg)
  }

  80% {
      -webkit-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      transform: rotate(-5deg)
  }

  100% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0)
  }
}

.InfiniteSwing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: InfiniteSwing;
  animation-name: InfiniteSwing
}

@-webkit-keyframes InfiniteVericalShake {
  0%,100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }
}

@keyframes InfiniteVericalShake {
  0%,100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0)
  }

  10%,30%,50%,70%,90% {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  20%,40%,60%,80% {
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px)
  }
}

.InfiniteVericalShake {
  -webkit-animation-name: InfiniteVericalShake;
  animation-name: InfiniteVericalShake
}

@-webkit-keyframes InfiniteRubberBand {
  0% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  30% {
      -webkit-transform: scaleX(1.25) scaleY(.75);
      transform: scaleX(1.25) scaleY(.75)
  }

  40% {
      -webkit-transform: scaleX(.75) scaleY(1.25);
      transform: scaleX(.75) scaleY(1.25)
  }

  60% {
      -webkit-transform: scaleX(1.15) scaleY(.85);
      transform: scaleX(1.15) scaleY(.85)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes InfiniteRubberBand {
  0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }

  30% {
      -webkit-transform: scaleX(1.25) scaleY(.75);
      -ms-transform: scaleX(1.25) scaleY(.75);
      transform: scaleX(1.25) scaleY(.75)
  }

  40% {
      -webkit-transform: scaleX(.75) scaleY(1.25);
      -ms-transform: scaleX(.75) scaleY(1.25);
      transform: scaleX(.75) scaleY(1.25)
  }

  60% {
      -webkit-transform: scaleX(1.15) scaleY(.85);
      -ms-transform: scaleX(1.15) scaleY(.85);
      transform: scaleX(1.15) scaleY(.85)
  }

  100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1)
  }
}

.InfiniteRubberBand {
  -webkit-animation-name: InfiniteRubberBand;
  animation-name: InfiniteRubberBand
}

@-webkit-keyframes InfiniteHorizontalFlip {
  from {
      -webkit-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0)
  }

  to {
      -webkit-transform: perspective(400px) rotateY(359deg);
      transform: perspective(400px) rotateY(359deg)
  }
}

@keyframes InfiniteHorizontalFlip {
  from {
      -webkit-transform: perspective(400px) rotateY(0);
      -ms-transform: perspective(400px) rotateY(0);
      transform: perspective(400px) rotateY(0)
  }

  to {
      -webkit-transform: perspective(400px) rotateY(359deg);
      -ms-transform: perspective(400px) rotateY(359deg);
      transform: perspective(400px) rotateY(359deg)
  }
}

.InfiniteHorizontalFlip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: InfiniteHorizontalFlip;
  animation-name: InfiniteHorizontalFlip
}

@-webkit-keyframes InfiniteVericalFlip {
  from {
      -webkit-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateY(0)
  }

  to {
      -webkit-transform: perspective(400px) rotateX(359deg);
      transform: perspective(400px) rotateY(359deg)
  }
}

@keyframes InfiniteVericalFlip {
  from {
      -webkit-transform: perspective(400px) rotateX(0);
      -ms-transform: perspective(400px) rotateX(0);
      transform: perspective(400px) rotateX(0)
  }

  to {
      -webkit-transform: perspective(400px) rotateX(359deg);
      -ms-transform: perspective(400px) rotateX(359deg);
      transform: perspective(400px) rotateX(359deg)
  }
}

.InfiniteVericalFlip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: InfiniteVericalFlip;
  animation-name: InfiniteVericalFlip
}

@-webkit-keyframes InfiniteHorizontalScaleFlip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

@keyframes InfiniteHorizontalScaleFlip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateY(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

.InfiniteHorizontalScaleFlip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: InfiniteHorizontalScaleFlip;
  animation-name: InfiniteHorizontalScaleFlip
}

@-webkit-keyframes InfiniteVerticalScaleFlip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateX(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateX(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateX(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateX(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateX(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateX(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

@keyframes InfiniteVerticalScaleFlip {
  0% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(-360deg) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateX(-360deg) scale(1);
      transform: perspective(400px) translateZ(0) rotateX(-360deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  40% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateX(-190deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateX(-190deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateX(-190deg) scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out
  }

  50% {
      -webkit-transform: perspective(400px) translateZ(150px) rotateX(-170deg) scale(1);
      -ms-transform: perspective(400px) translateZ(150px) rotateX(-170deg) scale(1);
      transform: perspective(400px) translateZ(150px) rotateX(-170deg) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  80% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(.95);
      -ms-transform: perspective(400px) translateZ(0) rotateX(0) scale(.95);
      transform: perspective(400px) translateZ(0) rotateX(0) scale(.95);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }

  100% {
      -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
      -ms-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
      transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in
  }
}

.InfiniteVerticalScaleFlip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: InfiniteVerticalScaleFlip;
  animation-name: InfiniteVerticalScaleFlip
}

.ubtn {
  border: inherit;
  -webkit-border-radius: none;
  border-radius: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4em;
  color: inherit;
  background: 0 0;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 25px 80px;
  outline: 0;
  text-align: center;
  text-decoration: none;
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  visibility: visible;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.ubtn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ubtn-data {
  z-index: 3;
  position: inherit;
  display: block;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit
}

button.ubtn {
  background: inherit;
  color: inherit
}

button.ubtn:active,button.ubtn:focus,button.ubtn:hover,button.ubtn:visited {
  color: inherit;
  background: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  outline: 0
}

.ubtn-ctn-left {
  display: block;
  text-align: left
}

.ubtn-ctn-center {
  display: block;
  text-align: center
}

.ubtn-ctn-right {
  display: block;
  text-align: right
}

.ubtn-ctn-inline {
  display: inline-block;
  text-align: center
}

a.ubtn-link {
  display: inline-block;
  margin: 0;
  color: inherit;
  text-decoration: none;
  max-width: 100%
}

a.ubtn-link:hover {
  color: inherit;
  text-decoration: none
}

a.ubtn-link .ubtn {
  margin: 0
}

button.ubtn-mini {
  padding: 5px 10px
}

button.ubtn-small {
  padding: 8px 16px
}

button.ubtn-normal {
  padding: 15px 30px
}

button.ubtn-large {
  padding: 21px 42px
}

.ubtn-block {
  display: block;
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
  padding-left: 0;
  padding-right: 0
}

button.ubtn-custom {
  padding: 0
}

@media(max-width: 768px) {
  .ubtn-small {
      padding:6px 12px
  }

  .ubtn-normal {
      padding: 10px 24px
  }

  .ubtn-large {
      padding: 12px 36px
  }
}

.ubtn-shd {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #eaeaea;
  -webkit-box-shadow: 0 5px #bebebe;
  box-shadow: 0 5px #bebebe;
  -webkit-transition: none!important;
  transition: none!important
}

.ubtn-sep-icon .ubtn-icon {
  position: absolute;
  top: 50%;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ubtn-sep-icon:hover .ubtn-icon {
  opacity: 1
}

.ubtn-sep-icon .ubtn-icon span {
  line-height: inherit;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ubtn.ubtn-sep-icon.ubtn-only-icon .ubtn-icon {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0)
}

.ubtn-sep-icon.ubtn-sep-icon-at-left .ubtn-icon {
  left: 20px;
  opacity: 1
}

.ubtn-img-container .ubtn-sep-icon.ubtn-sep-icon-at-left .ubtn-icon {
  left: 20px;
  opacity: 1
}

.ubtn-img-container .ubtn-sep-icon.ubtn-sep-icon-at-right .ubtn-icon {
  right: 20px;
  opacity: 1
}

.ubtn-sep-icon.ubtn-sep-icon-at-right .ubtn-icon {
  right: 20px;
  opacity: 1
}

.ubtn-sep-icon-left .ubtn-icon {
  left: -2em
}

.ubtn-sep-icon-left:hover .ubtn-icon {
  left: 10px
}

.ubtn-img-container .ubtn-sep-icon-left:hover .ubtn-icon {
  left: 10px
}

.ubtn-sep-icon-left-rev .ubtn-icon {
  left: 4em
}

.ubtn-sep-icon-left-rev:hover .ubtn-icon {
  left: 20px
}

.ubtn-sep-icon-right .ubtn-icon {
  right: -2em
}

.ubtn-img-container .ubtn-sep-icon-right:hover .ubtn-icon {
  right: 30px
}

.ubtn-sep-icon-right:hover .ubtn-icon {
  right: 5px
}

.ubtn-sep-icon-right-rev .ubtn-icon {
  right: 4em
}

.ubtn-sep-icon-right-rev:hover .ubtn-icon {
  right: 20px
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  line-height: 1
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-icon i,.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-icon i,.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-icon i,.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-text {
  display: inline-block;
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0)
}

.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-icon {
  top: -500px
}

.ubtn-sep-icon.ubtn-sep-icon-top-push:hover .ubtn-icon {
  top: 0
}

.ubtn-sep-icon.ubtn-sep-icon-top-push:hover .ubtn-text {
  display: inline-block;
  -webkit-transform: translateY(500px);
  -ms-transform: translateY(500px);
  transform: translateY(500px)
}

.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-top-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-top-push:hover .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-top-push:hover .ubtn-text {
  -webkit-transition: all .45s;
  transition: all .45s
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-icon {
  top: 500px
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push:hover .ubtn-icon {
  top: 0
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push:hover .ubtn-text {
  display: inline-block;
  -webkit-transform: translateY(-500px);
  -ms-transform: translateY(-500px);
  transform: translateY(-500px)
}

.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-bottom-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-bottom-push:hover .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-bottom-push:hover .ubtn-text {
  -webkit-transition: all .45s;
  transition: all .45s
}

.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-icon {
  left: -500px
}

.ubtn-sep-icon.ubtn-sep-icon-left-push:hover .ubtn-icon {
  left: 0
}

.ubtn-sep-icon.ubtn-sep-icon-left-push:hover .ubtn-text {
  display: inline-block;
  -webkit-transform: translateX(500px);
  -ms-transform: translateX(500px);
  transform: translateX(500px)
}

.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-left-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-left-push:hover .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-left-push:hover .ubtn-text {
  -webkit-transition: all .6s;
  transition: all .6s
}

.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-icon {
  left: 500px
}

.ubtn-sep-icon.ubtn-sep-icon-right-push:hover .ubtn-icon {
  left: 0
}

.ubtn-sep-icon.ubtn-sep-icon-right-push:hover .ubtn-text {
  display: inline-block;
  -webkit-transform: translateX(-500px);
  -ms-transform: translateX(-500px);
  transform: translateX(-500px)
}

.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-right-push .ubtn-text,.ubtn-sep-icon.ubtn-sep-icon-right-push:hover .ubtn-icon,.ubtn-sep-icon.ubtn-sep-icon-right-push:hover .ubtn-text {
  -webkit-transition: all .6s;
  transition: all .6s
}

.ubtn-hover {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: 0 0;
  z-index: 2;
  -webkit-transition: all .3s;
  transition: all .3s;
  -webkit-border-radius: inherit;
  border-radius: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ubtn-fade-bg .ubtn-hover {
  background: rgba(255,255,255,0);
  opacity: 0
}

.ubtn-fade-bg:hover .ubtn-hover {
  opacity: 1
}

.ubtn-top-bg .ubtn-hover {
  top: -100%;
  background: #ccc
}

.ubtn-top-bg:hover .ubtn-hover {
  top: 0
}

.ubtn-bottom-bg .ubtn-hover {
  top: 100%;
  background: #ccc
}

.ubtn-bottom-bg:hover .ubtn-hover {
  top: 0
}

.ubtn-left-bg .ubtn-hover {
  left: -100%;
  background: #ccc
}

.ubtn-left-bg:hover .ubtn-hover {
  left: 0
}

.ubtn-right-bg .ubtn-hover {
  left: 100%;
  background: #ccc
}

.ubtn-right-bg:hover .ubtn-hover {
  left: 0
}

.ubtn-center-hz-bg .ubtn-hover {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background: #ccc;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ubtn-center-hz-bg:hover .ubtn-hover {
  width: 90%;
  opacity: 1
}

.ubtn-center-hz-bg:active .ubtn-hover {
  width: 101%;
  opacity: 1
}

.ubtn-center-vt-bg .ubtn-hover {
  width: 101%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #ccc;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ubtn-center-vt-bg:hover .ubtn-hover {
  height: 75%;
  opacity: 1
}

.ubtn-center-vt-bg:active .ubtn-hover {
  height: 130%;
  opacity: 1
}

.ubtn-center-dg-bg .ubtn-hover {
  width: 100%;
  height: 0;
  top: 50%;
  left: 50%;
  background: #ccc;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg)
}

.ubtn-center-dg-bg:hover .ubtn-hover {
  height: 260%;
  opacity: 1
}

.ubtn-center-dg-bg:active .ubtn-hover {
  height: 400%;
  opacity: 1
}

.no-ubtn-shadow {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  -webkit-transition: all 50ms linear!important;
  transition: all 50ms linear!important
}

.ubtn-img-container {
  position: relative
}

.ubtn-img-container img {
  position: relative;
  display: block;
  max-width: 100%;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0 auto;
  padding: 0
}

.ubtn-img-container .ubtn {
  position: relative;
  display: block;
  margin: 0 auto;
  top: 0;
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none
}

.ubtn-img-container .ubtn.ubtn-right {
  right: 0;
  margin-right: 0;
  left: initial
}

.ubtn-img-container .ubtn.ubtn-left {
  left: 0;
  margin-left: 0;
  right: initial
}

.ubtn-img-container .ubtn-data {
  position: relative;
  display: inline-block
}

.ubtn-img-container a.ubtn-link {
  display: inline-block
}

.ulta-grow {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-grow:active,.ulta-grow:focus,.ulta-grow:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1)
}

.ulta-shrink {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-shrink:active,.ulta-shrink:focus,.ulta-shrink:hover {
  -webkit-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9)
}

@-webkit-keyframes ulta-pulse {
  25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  75% {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

@keyframes ulta-pulse {
  25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  75% {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

.ulta-pulse {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-pulse:active,.ulta-pulse:focus,.ulta-pulse:hover {
  -webkit-animation-name: ulta-pulse;
  animation-name: ulta-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

@-webkit-keyframes ulta-pulse-grow {
  to {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }
}

@keyframes ulta-pulse-grow {
  to {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }
}

.ulta-pulse-grow {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-pulse-grow:active,.ulta-pulse-grow:focus,.ulta-pulse-grow:hover {
  -webkit-animation-name: ulta-pulse-grow;
  animation-name: ulta-pulse-grow;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

@-webkit-keyframes ulta-pulse-shrink {
  to {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

@keyframes ulta-pulse-shrink {
  to {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }
}

.ulta-pulse-shrink {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-pulse-shrink:active,.ulta-pulse-shrink:focus,.ulta-pulse-shrink:hover {
  -webkit-animation-name: ulta-pulse-shrink;
  animation-name: ulta-pulse-shrink;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

@-webkit-keyframes ulta-push {
  50% {
      -webkit-transform: scale(.8);
      transform: scale(.8)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes ulta-push {
  50% {
      -webkit-transform: scale(.8);
      transform: scale(.8)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.ulta-push {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-push:active,.ulta-push:focus,.ulta-push:hover {
  -webkit-animation-name: ulta-push;
  animation-name: ulta-push;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-pop {
  50% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes ulta-pop {
  50% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.ulta-pop {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-pop:active,.ulta-pop:focus,.ulta-pop:hover {
  -webkit-animation-name: ulta-pop;
  animation-name: ulta-pop;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

.ulta-rotate {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-rotate:active,.ulta-rotate:focus,.ulta-rotate:hover {
  -webkit-transform: rotate(4deg);
  -ms-transform: rotate(4deg);
  transform: rotate(4deg)
}

.ulta-grow-rotate {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-grow-rotate:active,.ulta-grow-rotate:focus,.ulta-grow-rotate:hover {
  -webkit-transform: scale(1.1) rotate(4deg);
  -ms-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg)
}

.ulta-float {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-float:active,.ulta-float:focus,.ulta-float:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px)
}

.ulta-sink {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-sink:active,.ulta-sink:focus,.ulta-sink:hover {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px)
}

@-webkit-keyframes ulta-hover {
  50% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px)
  }

  100% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px)
  }
}

@keyframes ulta-hover {
  50% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px)
  }

  100% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px)
  }
}

.ulta-hover {
  display: inline-block;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-hover:active,.ulta-hover:focus,.ulta-hover:hover {
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
  -webkit-animation-name: ulta-hover;
  animation-name: ulta-hover;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

@-webkit-keyframes ulta-hang {
  50% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px)
  }

  100% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px)
  }
}

@keyframes ulta-hang {
  50% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px)
  }

  100% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px)
  }
}

.ulta-hang {
  display: inline-block;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-hang:active,.ulta-hang:focus,.ulta-hang:hover {
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  -webkit-animation-name: ulta-hang;
  animation-name: ulta-hang;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

.ulta-skew {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-skew:active,.ulta-skew:focus,.ulta-skew:hover {
  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg)
}

.ulta-skew-forward {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-skew-forward:active,.ulta-skew-forward:focus,.ulta-skew-forward:hover {
  -webkit-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  transform: skew(-10deg)
}

.ulta-skew-backward {
  display: inline-block;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-skew-backward:active,.ulta-skew-backward:focus,.ulta-skew-backward:hover {
  -webkit-transform: skew(10deg);
  -ms-transform: skew(10deg);
  transform: skew(10deg)
}

@-webkit-keyframes ulta-wobble-vertical {
  16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px)
  }

  33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px)
  }

  49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px)
  }

  66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px)
  }

  83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes ulta-wobble-vertical {
  16.65% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px)
  }

  33.3% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px)
  }

  49.95% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px)
  }

  66.6% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px)
  }

  83.25% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

.ulta-wobble-vertical {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-vertical:active,.ulta-wobble-vertical:focus,.ulta-wobble-vertical:hover {
  -webkit-animation-name: ulta-wobble-vertical;
  animation-name: ulta-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-horizontal {
  16.65% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px)
  }

  33.3% {
      -webkit-transform: translateX(-6px);
      transform: translateX(-6px)
  }

  49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px)
  }

  66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px)
  }

  83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

@keyframes ulta-wobble-horizontal {
  16.65% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px)
  }

  33.3% {
      -webkit-transform: translateX(-6px);
      transform: translateX(-6px)
  }

  49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px)
  }

  66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px)
  }

  83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

.ulta-wobble-horizontal {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-horizontal:active,.ulta-wobble-horizontal:focus,.ulta-wobble-horizontal:hover {
  -webkit-animation-name: ulta-wobble-horizontal;
  animation-name: ulta-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-to-bottom-right {
  16.65% {
      -webkit-transform: translate(8px,8px);
      transform: translate(8px,8px)
  }

  33.3% {
      -webkit-transform: translate(-6px,-6px);
      transform: translate(-6px,-6px)
  }

  49.95% {
      -webkit-transform: translate(4px,4px);
      transform: translate(4px,4px)
  }

  66.6% {
      -webkit-transform: translate(-2px,-2px);
      transform: translate(-2px,-2px)
  }

  83.25% {
      -webkit-transform: translate(1px,1px);
      transform: translate(1px,1px)
  }

  100% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0)
  }
}

@keyframes ulta-wobble-to-bottom-right {
  16.65% {
      -webkit-transform: translate(8px,8px);
      transform: translate(8px,8px)
  }

  33.3% {
      -webkit-transform: translate(-6px,-6px);
      transform: translate(-6px,-6px)
  }

  49.95% {
      -webkit-transform: translate(4px,4px);
      transform: translate(4px,4px)
  }

  66.6% {
      -webkit-transform: translate(-2px,-2px);
      transform: translate(-2px,-2px)
  }

  83.25% {
      -webkit-transform: translate(1px,1px);
      transform: translate(1px,1px)
  }

  100% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0)
  }
}

.ulta-wobble-to-bottom-right {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-to-bottom-right:active,.ulta-wobble-to-bottom-right:focus,.ulta-wobble-to-bottom-right:hover {
  -webkit-animation-name: ulta-wobble-to-bottom-right;
  animation-name: ulta-wobble-to-bottom-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-to-top-right {
  16.65% {
      -webkit-transform: translate(8px,-8px);
      transform: translate(8px,-8px)
  }

  33.3% {
      -webkit-transform: translate(-6px,6px);
      transform: translate(-6px,6px)
  }

  49.95% {
      -webkit-transform: translate(4px,-4px);
      transform: translate(4px,-4px)
  }

  66.6% {
      -webkit-transform: translate(-2px,2px);
      transform: translate(-2px,2px)
  }

  83.25% {
      -webkit-transform: translate(1px,-1px);
      transform: translate(1px,-1px)
  }

  100% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0)
  }
}

@keyframes ulta-wobble-to-top-right {
  16.65% {
      -webkit-transform: translate(8px,-8px);
      transform: translate(8px,-8px)
  }

  33.3% {
      -webkit-transform: translate(-6px,6px);
      transform: translate(-6px,6px)
  }

  49.95% {
      -webkit-transform: translate(4px,-4px);
      transform: translate(4px,-4px)
  }

  66.6% {
      -webkit-transform: translate(-2px,2px);
      transform: translate(-2px,2px)
  }

  83.25% {
      -webkit-transform: translate(1px,-1px);
      transform: translate(1px,-1px)
  }

  100% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0)
  }
}

.ulta-wobble-to-top-right {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-to-top-right:active,.ulta-wobble-to-top-right:focus,.ulta-wobble-to-top-right:hover {
  -webkit-animation-name: ulta-wobble-to-top-right;
  animation-name: ulta-wobble-to-top-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-top {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

@keyframes ulta-wobble-top {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

.ulta-wobble-top {
  display: inline-block;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-top:active,.ulta-wobble-top:focus,.ulta-wobble-top:hover {
  -webkit-animation-name: ulta-wobble-top;
  animation-name: ulta-wobble-top;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-bottom {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

@keyframes ulta-wobble-bottom {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

.ulta-wobble-bottom {
  display: inline-block;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-bottom:active,.ulta-wobble-bottom:focus,.ulta-wobble-bottom:hover {
  -webkit-animation-name: ulta-wobble-bottom;
  animation-name: ulta-wobble-bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-wobble-skew {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

@keyframes ulta-wobble-skew {
  16.65% {
      -webkit-transform: skew(-12deg);
      transform: skew(-12deg)
  }

  33.3% {
      -webkit-transform: skew(10deg);
      transform: skew(10deg)
  }

  49.95% {
      -webkit-transform: skew(-6deg);
      transform: skew(-6deg)
  }

  66.6% {
      -webkit-transform: skew(4deg);
      transform: skew(4deg)
  }

  83.25% {
      -webkit-transform: skew(-2deg);
      transform: skew(-2deg)
  }

  100% {
      -webkit-transform: skew(0);
      transform: skew(0)
  }
}

.ulta-wobble-skew {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-wobble-skew:active,.ulta-wobble-skew:focus,.ulta-wobble-skew:hover {
  -webkit-animation-name: ulta-wobble-skew;
  animation-name: ulta-wobble-skew;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

@-webkit-keyframes ulta-buzz {
  50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }
}

@keyframes ulta-buzz {
  50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }
}

.ulta-buzz {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-buzz:active,.ulta-buzz:focus,.ulta-buzz:hover {
  -webkit-animation-name: ulta-buzz;
  animation-name: ulta-buzz;
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

@-webkit-keyframes ulta-buzz-out {
  10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }

  30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }

  50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg)
  }

  60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg)
  }

  70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg)
  }

  80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg)
  }

  90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0)
  }

  100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0)
  }
}

@keyframes ulta-buzz-out {
  10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }

  30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg)
  }

  40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg)
  }

  50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg)
  }

  60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg)
  }

  70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg)
  }

  80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg)
  }

  90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0)
  }

  100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0)
  }
}

.ulta-buzz-out {
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.ulta-buzz-out:active,.ulta-buzz-out:focus,.ulta-buzz-out:hover {
  -webkit-animation-name: ulta-buzz-out;
  animation-name: ulta-buzz-out;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

.ubtn-img-container .ubtn-data.ubtn-icon i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ubtn-img-container .ubtn-data.ubtn-icon {
  height: 100%;
  position: absolute
}

.ubtn-hover {
  opacity: 0
}

.ubtn-hover-active {
  opacity: 1
}

.ubtn-img-container .ubtn-sep-icon-right .ubtn-data.ubtn-text {
  left: -10px
}

.ubtn-img-container .ubtn-sep-icon-at-left .ubtn-data.ubtn-text {
  right: -15px
}

.ubtn-img-container .ubtn-ctn-center {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ubtn-img-container .ubtn-ctn-inline {
  position: absolute;
  width: auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.ubtn-img-container .ubtn-ctn-left {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%)
}

.ubtn-img-container .ubtn-ctn-right {
  position: absolute;
  right: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%)
}

.uvc-heading {
  text-align: center
}

.uvc-heading .uvc-main-heading div,.uvc-heading .uvc-main-heading h1,.uvc-heading .uvc-main-heading h2,.uvc-heading .uvc-main-heading h3,.uvc-heading .uvc-main-heading h4,.uvc-heading .uvc-main-heading h5,.uvc-heading .uvc-main-heading h6,.uvc-heading .uvc-main-heading p,.uvc-heading .uvc-main-heading span {
  margin: 0;
  padding: 0;
  line-height: normal
}

.uvc-heading-spacer {
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden
}

.uvc-heading-spacer .aio-icon {
  vertical-align: text-top
}

.uvc-heading-spacer img {
  vertical-align: middle;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  max-width: 100%
}

.uvc-heading-spacer.line_with_icon:before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: 0;
  border-width: 0;
  top: 50%
}

.uvc-heading-spacer.line_with_icon:after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 0;
  border-width: 0;
  top: 50%
}

.uvc-headings-line {
  display: block;
  width: 100%;
  max-width: 100%
}

.uvc-sub-heading {
  line-height: normal;
  margin: 0 auto
}

.uvc-sub-heading div,.uvc-sub-heading p {
  font-family: inherit!important;
  font-weight: inherit!important;
  font-size: inherit!important;
  color: inherit!important;
  line-height: inherit!important
}

.uvc-heading p {
  word-break: normal;
  word-wrap: normal;
  -ms-word-break: normal;
  -ms-word-wrap: normal
}

.aio-icon-component,.wpb_column>.wpb_wrapper .aio-icon-component {
  margin-bottom: 35px
}

.aio-icon-component .aio-icon,.aio-icon-component .img-icon {
  -webkit-transition: -webkit-transform .2s linear;
  transition: -webkit-transform .2s linear;
  transition: transform .2s linear;
  transition: transform .2s linear,-webkit-transform .2s linear
}

.aio-icon-header h3.aio-icon-title {
  margin: 0;
  display: block;
  font-size: 16px;
  line-height: 1.2em
}

.aio-icon-description {
  width: 100%;
  display: block;
  margin-top: 0
}

.aio-icon-header+.aio-icon-description {
  margin-top: 10px
}

.aio-icon-read {
  display: block;
  padding: 5px 0;
  text-decoration: none
}

a.aio-icon-box-link {
  text-decoration: none;
  border: none;
  display: block
}

.default-icon .aio-icon-default {
  display: table-cell;
  vertical-align: middle
}

.default-icon .aio-icon-default .aio-icon,.default-icon .aio-icon-default .aio-icon-img {
  margin-right: 15px
}

.default-icon .aio-icon-header {
  display: table-cell;
  vertical-align: middle
}

.top-icon .aio-icon,.top-icon .aio-icon-description,.top-icon .aio-icon-header,.top-icon .aio-icon-img {
  display: block;
  text-align: center;
  margin: 0 auto
}

.top-icon .aio-icon-header h3.aio-icon-title {
  margin: 25px 0 15px
}

.top-icon .aio-icon-description {
  margin-top: 15px
}

.top-icon .aio-icon-header+.aio-icon-description {
  margin-top: 0
}

.aio-icon-box.left-icon {
  display: inline-block
}

.left-icon .aio-icon-left {
  display: table-cell;
  vertical-align: top
}

.left-icon .aio-icon-left .aio-icon,.left-icon .aio-icon-left .aio-icon-img {
  margin-right: 15px
}

.left-icon .aio-ibd-block {
  display: table-cell;
  vertical-align: middle
}

.left-icon .aio-icon-description,.left-icon .aio-icon-header {
  float: left;
  width: 100%
}

.square_box-icon .aio-icon,.square_box-icon .aio-icon-description,.square_box-icon .aio-icon-header,.square_box-icon .aio-icon-img {
  display: block;
  text-align: center;
  margin: 0 auto
}

.square_box-icon .aio-icon-img {
  display: block!important
}

.square_box-icon .aio-icon-description,.square_box-icon .aio-icon-header {
  padding: 0 5%
}

.square_box-icon .aio-icon-description {
  padding-bottom: 1em
}

.square_box-icon .aio-icon-header h3.aio-icon-title {
  margin: 15px 0 15px
}

.square_box-icon .aio-icon-square_box .aio-icon,.square_box-icon .aio-icon-square_box .aio-icon-img {
  position: absolute;
  right: 0;
  left: 0
}

.square_box-icon .aio-icon-square_box {
  height: 100%;
  width: 100%;
  position: relative;
  display: block
}

.aio-icon-box.top-icon {
  text-align: center
}

.style_2 .aio-icon-box .aio-icon,.style_2 .aio-icon-box .img-icon {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.style_2 .aio-icon-box:hover .aio-icon,.style_2 .aio-icon-box:hover .img-icon {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2)
}

.style_3 .aio-icon-box .aio-icon,.style_3 .aio-icon-box .img-icon {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.style_3 .aio-icon-box:hover .aio-icon,.style_3 .aio-icon-box:hover .img-icon {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px)
}

.style_2 .aio-icon-box .aio-icon.animated,.style_2 .aio-icon-box .img-icon.animated,.style_3 .aio-icon-box .aio-icon.animated,.style_3 .aio-icon-box .img-icon.animated {
  -webkit-animation-fill-mode: initial;
  animation-fill-mode: initial
}

.aio-icon-box.right-icon {
  display: inline-block;
  width: 100%
}

.right-icon .aio-icon-right {
  display: table-cell;
  vertical-align: top;
  width: 100%
}

.right-icon .aio-icon-right .aio-icon,.right-icon .aio-icon-right .aio-icon-img {
  margin-left: 15px
}

.right-icon .aio-ibd-block {
  display: table-cell;
  vertical-align: middle;
  width: 100%
}

.right-icon .aio-ibd-block,.right-icon .aio-ibd-block *,.right-icon .aio-icon-description,.right-icon .aio-icon-header h3.aio-icon-title {
  text-align: right
}

.right-icon .aio-icon-description,.right-icon .aio-icon-header {
  float: right;
  width: 100%
}

.heading-right-icon .aio-icon-heading-right {
  display: table-cell;
  vertical-align: middle;
  width: 100%
}

.aio-icon-heading-right+.aio-icon-description {
  margin-top: 10px
}

.heading-right-icon .aio-icon-heading-right .aio-icon,.heading-right-icon .aio-icon-heading-right .aio-icon-img {
  margin-left: 15px
}

.heading-right-icon .aio-icon-header {
  display: table-cell;
  vertical-align: middle;
  width: 100%
}

.heading-right-icon .aio-icon-description,.heading-right-icon .aio-icon-description *,.heading-right-icon .aio-icon-header .aio-icon-title {
  text-align: right
}

.bsf_tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  visibility: visible;
  opacity: 0
}

.bsf_tooltip.in {
  opacity: .9
}

.bsf_tooltip.top {
  padding: 5px 0;
  margin-top: -3px
}

.bsf_tooltip.right {
  padding: 0 5px;
  margin-left: 3px
}

.bsf_tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px
}

.bsf_tooltip.left {
  padding: 0 5px;
  margin-left: -3px
}

.bsf_tooltip-inner {
  max-width: 200px;
  padding: 5px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #3b3b3b;
  -webkit-border-radius: 0;
  border-radius: 0
}

.bsf_tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.bsf_tooltip.top .bsf_tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #3b3b3b
}

.bsf_tooltip.top-left .bsf_tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: #3b3b3b
}

.bsf_tooltip.top-right .bsf_tooltip-arrow {
  right: 5px;
  bottom: 0;
  border-width: 5px 5px 0;
  border-top-color: #3b3b3b
}

.bsf_tooltip.right .bsf_tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #3b3b3b
}

.bsf_tooltip.left .bsf_tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #3b3b3b
}

.bsf_tooltip.bottom .bsf_tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #3b3b3b
}

.bsf_tooltip.bottom-left .bsf_tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #3b3b3b
}

.bsf_tooltip.bottom-right .bsf_tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: #3b3b3b
}

.bsf_tooltip.fade {
  opacity: 0;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear
}

.bsf_tooltip.fade.in {
  opacity: 1;
  -webkit-transition: opacity .3s linear;
  transition: opacity .3s linear
}

.ult-new-ib,.ult-new-ib img {
  display: block;
  position: relative
}

.ult-new-ib {
  z-index: 1;
  overflow: hidden;
  margin: 0 0 35px;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ult-new-ib,.wpb_column>.wpb_wrapper .ult-new-ib {
  margin-bottom: 35px
}

.ult-new-ib * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ult-new-ib *,.ult-new-ib .ult-new-ib-desc {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box
}

.ult-new-ib img {
  min-width: 100%;
  max-width: 100%;
  -webkit-transition: opacity .35s;
  transition: opacity .35s
}

.ult-new-ib .ult-new-ib-desc {
  padding: 15px;
  color: #fff;
  font-size: 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.ult-new-ib .ult-new-ib-desc::after,.ult-new-ib .ult-new-ib-desc::before {
  pointer-events: none
}

.ult-new-ib .ult-new-ib-desc,.ult-new-ib a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.ult-new-ib .ult-new-ib-content {
  font-size: 13px;
  line-height: 1.6em
}

.ult-new-ib .ult-new-ib-content p {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit
}

.ult-new-ib .ult-new-ib-title,.ult-new-ib p {
  letter-spacing: normal;
  padding: 0;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  font-style: inherit;
  color: inherit
}

.ult-ib-effect-style10.ult-ib2-min-height:not(.ult-ib2-min-height) img,.ult-ib-effect-style11.ult-ib2-min-height:not(.ult-ib2-min-height) img,.ult-ib-effect-style14.ult-ib2-min-height:not(.ult-ib2-min-height) img,.ult-ib-effect-style15.ult-ib2-min-height:not(.ult-ib2-min-height) img,.ult-ib-effect-style7.ult-ib2-min-height:not(.ult-ib2-min-height) img {
  max-width: none!important;
  width: auto!important
}

.ult-new-ib a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0
}

.ult-new-ib a.ult-new-ib-link {
  display: block;
  background: 0 0
}

.ult-ib-effect-style1 img {
  width: -webkit-calc(100% + 50px)!important;
  width: calc(100% + 50px)!important;
  max-width: -webkit-calc(100% + 50px)!important;
  max-width: calc(100% + 50px)!important;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-40px,0,0);
  transform: translate3d(-40px,0,0)
}

.ult-ib-effect-style1 .ult-new-ib-desc {
  top: auto;
  bottom: 0;
  min-height: 25%;
  height: auto;
  max-height: 100%;
  text-align: left
}

.ult-ib-effect-style1 .ult-new-ib-content,.ult-ib-effect-style1 .ult-new-ib-title {
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
  -webkit-transition-delay: .05s;
  transition-delay: .05s;
  -webkit-transition-duration: .35s;
  transition-duration: .35s
}

.ult-ib-effect-style1 .ult-new-ib-title {
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style1 .ult-new-ib-content {
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity .2s,-webkit-transform .35s;
  transition: opacity .2s,-webkit-transform .35s;
  transition: opacity .2s,transform .35s;
  transition: opacity .2s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style1:hover .ult-new-ib-content {
  opacity: 1;
  -webkit-transition-delay: .05s;
  transition-delay: .05s;
  -webkit-transition-duration: .35s;
  transition-duration: .35s
}

.ult-ib-effect-style1:hover .ult-new-ib-content,.ult-ib-effect-style1:hover .ult-new-ib-title,.ult-ib-effect-style1:hover img {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  -webkit-transition-delay: .05s;
  transition-delay: .05s;
  -webkit-transition-duration: .35s;
  transition-duration: .35s
}

.ult-ib-effect-style1.ult-ib2-min-height img {
  width: auto!important;
  max-width: none!important;
  height: 100%
}

.ult-ib-effect-style2 img {
  width: 100%
}

.ult-ib-effect-style2 .ult-new-ib-desc::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0,50%,0);
  transform: translate3d(0,50%,0)
}

.ult-ib-effect-style2 .ult-new-ib-title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform .35s,color .35s;
  -webkit-transition: color .35s,-webkit-transform .35s;
  transition: color .35s,-webkit-transform .35s;
  transition: transform .35s,color .35s;
  transition: transform .35s,color .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,-50%,0);
  transform: translate3d(0,-50%,0)
}

.ult-ib-effect-style2 .ult-new-ib-content,.ult-ib-effect-style2 .ult-new-ib-desc::before {
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style2 .ult-new-ib-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  max-height: 50%;
  opacity: 0;
  -webkit-transform: translate3d(0,10px,0);
  transform: translate3d(0,10px,0)
}

.ult-ib-effect-style2:hover .ult-new-ib-title {
  color: #fff;
  -webkit-transform: translate3d(0,-40px,0);
  transform: translate3d(0,-40px,0)
}

.ult-ib-effect-style2:hover .ult-new-ib-content,.ult-ib-effect-style2:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style2.ult-ib2-min-height img {
  width: auto;
  max-width: none;
  height: 100%
}

.ult-ib-effect-style3 .ult-new-ib-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  text-align: left;
  -webkit-transform: translate3d(0,-30px,0);
  transform: translate3d(0,-30px,0)
}

.ult-ib-effect-style3 .ult-new-ib-desc::before,.ult-ib-effect-style3 .ult-new-ib-title {
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style3:hover .ult-new-ib-desc::before,.ult-ib-effect-style3:hover .ult-new-ib-title {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style3 .ult-new-ib-content {
  max-height: -webkit-calc(100% - 60px - 1.5em);
  max-height: calc(100% - 60px - 1.5em);
  overflow: hidden
}

.ult-ib-effect-style4 img {
  width: -webkit-calc(100% + 40px)!important;
  width: calc(100% + 40px)!important;
  max-width: -webkit-calc(100% + 40px)!important;
  max-width: calc(100% + 40px)!important
}

.ult-ib-effect-style4 .ult-new-ib-desc {
  padding: 30px
}

.ult-ib-effect-style4 .ult-new-ib-desc::after,.ult-ib-effect-style4 .ult-new-ib-desc::before {
  position: absolute;
  content: "";
  opacity: 0
}

.ult-ib-effect-style4 .ult-new-ib-desc::before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  -ms-transform: scale(0,1);
  transform: scale(0,1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.ult-ib-effect-style4 .ult-new-ib-desc::after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  -ms-transform: scale(1,0);
  transform: scale(1,0);
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0
}

.ult-ib-effect-style4 .ult-new-ib-title {
  padding-top: 50px;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style4 .ult-new-ib-content {
  padding: 10px 30px;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translate3d(0,-10px,0);
  transform: translate3d(0,-10px,0)
}

.ult-ib-effect-style4 .ult-new-ib-title,.ult-ib-effect-style4 img {
  -webkit-transform: translate3d(-30px,0,0);
  transform: translate3d(-30px,0,0)
}

.ult-ib-effect-style4 .ult-new-ib-content,.ult-ib-effect-style4 .ult-new-ib-desc::after,.ult-ib-effect-style4 .ult-new-ib-desc::before,.ult-ib-effect-style4 img {
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style4.ult-ib2-min-height img {
  height: 100%;
  max-width: none!important;
  width: auto!important
}

.ult-ib-effect-style4:hover img {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style4:hover .ult-new-ib-desc::after,.ult-ib-effect-style4:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style4:hover .ult-new-ib-content,.ult-ib-effect-style4:hover .ult-new-ib-title {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style4:hover .ult-new-ib-content,.ult-ib-effect-style4:hover .ult-new-ib-desc::after,.ult-ib-effect-style4:hover .ult-new-ib-title,.ult-ib-effect-style4:hover img {
  -webkit-transition-delay: .15s;
  transition-delay: .15s
}

.ult-ib-effect-style5 .ult-new-ib-desc {
  top: auto;
  bottom: 0;
  padding: 15px;
  height: auto;
  background: #f2f2f2;
  color: #3c4a50;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0)
}

.ult-ib-effect-style5 .ult-new-ib-content {
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 0;
  width: 100%;
  padding: 25px;
  color: #fff;
  text-transform: none;
  opacity: 0;
  -webkit-transition: opacity .35s;
  transition: opacity .35s
}

.ult-ib-effect-style5 .ult-new-ib-title {
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,200%,0);
  transform: translate3d(0,200%,0);
  text-align: center
}

.ult-ib-effect-style5:hover .ult-new-ib-content,.ult-ib-effect-style5:hover .ult-new-ib-content * {
  opacity: 1!important;
  z-index: 99!important;
  -webkit-backface-visibility: hidden!important;
  backface-visibility: hidden!important
}

.ult-ib-effect-style5:hover .ult-new-ib-desc,.ult-ib-effect-style5:hover .ult-new-ib-title {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.ult-ib-effect-style5:hover .ult-new-ib-title {
  -webkit-transition-delay: .05s;
  transition-delay: .05s
}

.ult-ib-effect-style6 .ult-new-ib-desc {
  padding: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.ult-ib-effect-style6 .ult-new-ib-desc::before {
  position: absolute;
  content: "";
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff
}

.ult-ib-effect-style6 .ult-new-ib-title {
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  webkit-transform: translate3d(0,50%,0);
  -webkit-transform: translate3d(0,50%,0);
  transform: translate3d(0,50%,0)
}

.ult-ib-effect-style6 .ult-new-ib-content,.ult-ib-effect-style6 .ult-new-ib-desc::before {
  opacity: 0;
  max-width: 100%;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0)
}

.ult-ib-effect-style6:hover .ult-new-ib-title {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style6:hover .ult-new-ib-content,.ult-ib-effect-style6:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style6.ult-ib2-min-height img {
  height: 100%;
  max-width: none;
  width: auto
}

.ult-ib-effect-style7 .ult-new-ib-desc {
  text-align: right
}

.ult-ib-effect-style7 .ult-new-ib-content,.ult-ib-effect-style7 .ult-new-ib-title {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0
}

.ult-ib-effect-style7 .ult-new-ib-title {
  top: 30px;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0)
}

.ult-ib-effect-style7:hover .ult-new-ib-title {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style7 .ult-new-ib-title::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0)
}

.ult-ib-effect-style7 .ult-new-ib-content,.ult-ib-effect-style7 .ult-new-ib-title::after {
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style7 .ult-new-ib-content {
  bottom: 30px;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0)
}

.ult-ib-effect-style7:hover .ult-new-ib-content,.ult-ib-effect-style7:hover .ult-new-ib-title::after {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style7.ult-ib2-min-height img {
  max-width: none;
  width: auto;
  height: 100%
}

.ult-ib-effect-style8 img {
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15)
}

.ult-ib-effect-style8:hover img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style8 .ult-new-ib-title {
  margin-top: 20%;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0)
}

.ult-ib-effect-style8:hover .ult-new-ib-title {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style8 .ult-new-ib-content {
  margin: 1em 0 0;
  padding: 35px;
  border: 1px solid #fff;
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,20px,0) scale(1.1);
  transform: translate3d(0,20px,0) scale(1.1)
}

.ult-ib-effect-style8:hover .ult-new-ib-content {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1)
}

.ult-ib-effect-style9 img {
  width: -webkit-calc(100% + 60px)!important;
  width: calc(100% + 60px)!important;
  max-width: -webkit-calc(100% + 60px)!important;
  max-width: calc(100% + 60px)!important;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-50px,0,0);
  transform: translate3d(-50px,0,0)
}

.ult-ib-effect-style9:hover img {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style9 .ult-new-ib-desc::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-20px,0,0);
  transform: translate3d(-20px,0,0)
}

.ult-ib-effect-style9 .ult-new-ib-desc {
  padding: 45px;
  text-align: left
}

.ult-ib-effect-style9 .ult-new-ib-title {
  padding: 30% 0 10px
}

.ult-ib-effect-style9 .ult-new-ib-content {
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0)
}

.ult-ib-effect-style9:hover .ult-new-ib-content,.ult-ib-effect-style9:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style9.ult-ib2-min-height img {
  height: 100%;
  width: auto!important;
  max-width: none!important
}

.ult-ib-effect-style10 .ult-new-ib-desc::after,.ult-ib-effect-style10 .ult-new-ib-desc::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  content: '';
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style10 .ult-new-ib-desc::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0,1);
  -ms-transform: scale(0,1);
  transform: scale(0,1)
}

.ult-ib-effect-style10 .ult-new-ib-desc::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scale(1,0);
  -ms-transform: scale(1,0);
  transform: scale(1,0)
}

.ult-ib-effect-style10:hover .ult-new-ib-desc::after,.ult-ib-effect-style10:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style10 .ult-new-ib-title {
  padding: 45px 35px 0;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,-20px,0);
  transform: translate3d(0,-20px,0)
}

.ult-ib-effect-style10 .ult-new-ib-content {
  padding: 20px 45px;
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,20px,0);
  transform: translate3d(0,20px,0)
}

.ult-ib-effect-style10:hover .ult-new-ib-content,.ult-ib-effect-style10:hover .ult-new-ib-title {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style10.ult-ib2-min-height img {
  height: 100%;
  max-width: none;
  width: auto
}

.ult-ib-effect-style11 {
  -webkit-perspective: 1000px;
  perspective: 1000px
}

.ult-ib-effect-style11 img {
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2)
}

.ult-new-ib.ult-ib-effect-style11.ult-ib2-min-height img {
  height: 100%;
  width: auto!important;
  max-width: none!important
}

.ult-ib-effect-style11:hover img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style11 .ult-new-ib-desc::after,.ult-ib-effect-style11 .ult-new-ib-desc::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 1px;
  background: #fff;
  content: '';
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-50%,-50%,0);
  transform: translate3d(-50%,-50%,0)
}

.ult-ib-effect-style11:hover .ult-new-ib-desc::before {
  opacity: .5;
  -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
  transform: translate3d(-50%,-50%,0) rotate(45deg)
}

.ult-ib-effect-style11:hover .ult-new-ib-desc::after {
  opacity: .5;
  -webkit-transform: translate3d(-50%,-50%,0) rotate(-45deg);
  transform: translate3d(-50%,-50%,0) rotate(-45deg)
}

.ult-ib-effect-style11 .ult-new-ib-content,.ult-ib-effect-style11 .ult-new-ib-title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style11 .ult-new-ib-title {
  -webkit-transform: translate3d(0,-50px,0);
  transform: translate3d(0,-50px,0)
}

.ult-ib-effect-style11:hover .ult-new-ib-title {
  -webkit-transform: translate3d(0,-60px,0);
  transform: translate3d(0,-60px,0)
}

.ult-ib-effect-style11 .ult-new-ib-content {
  padding: 2px 25px;
  -webkit-transform: translate3d(0,15px,0);
  transform: translate3d(0,15px,0)
}

.ult-ib-effect-style11:hover .ult-new-ib-content {
  -webkit-transform: translate3d(0,25px,0);
  transform: translate3d(0,25px,0)
}

.ult-ib-effect-style12 .ult-new-ib-desc::after {
  position: absolute;
  content: "";
  right: 30px;
  bottom: 30px;
  left: 30px;
  height: -webkit-calc(50% - 30px);
  height: calc(50% - 30px);
  border: 7px solid #fff;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,-100%,0);
  transform: translate3d(0,-100%,0)
}

.ult-ib-effect-style12:hover .ult-new-ib-desc::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style12 .ult-new-ib-desc {
  padding: 45px;
  text-align: left
}

.ult-ib-effect-style12 .ult-new-ib-content {
  position: absolute;
  right: 60px;
  bottom: 60px;
  left: 60px;
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(0,-100px,0);
  transform: translate3d(0,-100px,0)
}

.ult-ib-effect-style12:hover .ult-new-ib-content {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style13 img {
  width: -webkit-calc(100% + 20px)!important;
  width: calc(100% + 20px)!important;
  max-width: -webkit-calc(100% + 20px)!important;
  max-width: calc(100% + 20px)!important;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.ult-ib-effect-style13:hover img {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style13 .ult-new-ib-desc {
  text-align: left
}

.ult-ib-effect-style13 .ult-new-ib-title {
  position: relative;
  overflow: hidden;
  padding: 5px 0 10px
}

.ult-ib-effect-style13 .ult-new-ib-title::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transition: -webkit-transform .35s;
  transition: -webkit-transform .35s;
  transition: transform .35s;
  transition: transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0)
}

.ult-ib-effect-style13:hover .ult-new-ib-title::after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style13 .ult-new-ib-content {
  padding: 15px 0;
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0)
}

.ult-ib-effect-style13:hover .ult-new-ib-content {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style13.ult-ib2-min-height img {
  width: auto!important;
  max-width: none!important;
  height: 100%
}

.ult-ib-effect-style14 img {
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: scale(1.12);
  -ms-transform: scale(1.12);
  transform: scale(1.12)
}

.ult-ib-effect-style14.ult-ib2-min-height img {
  height: 100%;
  width: auto;
  max-width: none
}

.ult-ib-effect-style14:hover img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style14 .ult-new-ib-desc {
  padding: 45px
}

.ult-ib-effect-style14 .ult-new-ib-desc::before {
  position: absolute;
  content: "";
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #fff;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1)
}

.ult-ib-effect-style14 .ult-new-ib-content,.ult-ib-effect-style14 .ult-new-ib-desc::before {
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s
}

.ult-ib-effect-style14 .ult-new-ib-title {
  padding: 10px 0 15px
}

.ult-ib-effect-style14 .ult-new-ib-content {
  margin: 0 auto;
  max-width: 90%;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5)
}

.ult-ib-effect-style14:hover .ult-new-ib-content,.ult-ib-effect-style14:hover .ult-new-ib-desc::before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.ult-ib-effect-style15 img {
  width: -webkit-calc(100% + 60px)!important;
  width: calc(100% + 60px)!important;
  max-width: -webkit-calc(100% + 60px)!important;
  max-width: calc(100% + 60px)!important;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-30px,0,0) scale(1.12);
  transform: translate3d(-30px,0,0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.ult-ib-effect-style15:hover img {
  -webkit-transform: translate3d(0,0,0) scale(1);
  transform: translate3d(0,0,0) scale(1)
}

.ult-ib-effect-style15 .ult-new-ib-title {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 15px 20px;
  width: auto
}

.ult-ib-effect-style15 .ult-new-ib-content {
  padding: 0 10px 0 0;
  width: 50%;
  border-right: 1px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,-webkit-transform .35s;
  transition: opacity .35s,transform .35s;
  transition: opacity .35s,transform .35s,-webkit-transform .35s;
  -webkit-transform: translate3d(-40px,0,0);
  transform: translate3d(-40px,0,0)
}

.ult-ib-effect-style15:hover .ult-new-ib-content {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}

.ult-ib-effect-style15.ult-ib2-min-height img {
  height: 100%;
  width: auto!important;
  max-width: none!important
}

.ult-new-ib.ult-ib-effect-style15:not(.ult-ib2-min-height) .ult-ib2-toggle-size,.ult-new-ib.ult-ib-effect-style5 .ult-ib2-toggle-size {
  left: 50%;
  width: auto!important;
  height: 100%;
  max-width: none;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%)
}

.ult-new-ib img {
  border: none;
  padding: 0;
  margin: 0
}

.ult-ib-effect-style15 p {
  width: 100%;
  border-right: none
}

.ult-hide {
  display: none!important
}

button.overlay-show,button.overlay-show-boxes,button.overlay-show-cornershape,button.overlay-show-genie,div.ult-overlay-close,img.overlay-show,img.overlay-show-boxes,img.overlay-show-cornershape,img.overlay-show-genie,span.overlay-show {
  cursor: pointer
}

.btn-modal {
  display: block;
  margin: 2px;
  padding: 8px 13px;
  font-size: 14px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all .5s;
  transition: all .5s
}

.btn-modal-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  -webkit-border-radius: 6px;
  border-radius: 6px
}

.btn-modal-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px
}

.btn-modal-md {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px
}

.btn-modal-block {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0
}

.btn-modal:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0
}

button.ult-align-center {
  margin: 0 auto;
  display: block
}

button.ult-align-left {
  float: left
}

button.ult-align-right {
  float: right
}

img.ult-modal-img {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  max-width: 100%;
  display: block
}

img.ult-modal-inside-img {
  width: 48px;
  vertical-align: middle;
  padding: 5px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0
}

img.ult-align-center {
  margin: 0 auto
}

img.ult-align-left {
  float: left
}

img.ult-align-right {
  float: right
}

span.ult-align-left {
  text-align: left
}

span.ult-align-center {
  text-align: center;
  display: table;
  margin: 0 auto
}

span.ult-align-right {
  display: block;
  text-align: right
}

.ult-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(28,28,28,.9);
  z-index: 9999;
  z-index: 16777271;
  visibility: hidden
}

.ult_modal-auto-top.ult-open {
  display: block;
  overflow: auto;
  overflow-y: scroll
}

.ult-overlay-close {
  width: 80px;
  height: 80px;
  position: fixed;
  overflow: hidden;
  border: 0;
  text-indent: 200%;
  color: transparent;
  outline: 0;
  z-index: 9999
}

.ult-overlay-close-inside {
  width: 100%;
  height: 100%;
  background: url(//gandhinagar-newlaunch.com/wp-content/plugins/Ultimate_VC_Addons/assets/img/cross.png) no-repeat center center
}

.ult-overlay-close.top-right {
  right: 35px;
  top: 35px
}

.ult-overlay-close.top-left {
  left: 35px;
  top: 35px
}

.ult-overlay-close.popup-top-right {
  left: 100%;
  bottom: 100%
}

.ult-overlay-close.popup-top-left {
  bottom: 100%;
  right: 100%
}

.ult-overlay-close.popup-edge-top-right {
  left: auto
}

.ult-overlay-close.popup-edge-top-left {
  right: auto
}

.ult-overlay .ult-overlay-close:active {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  outline: 0!important
}

.ult_modal {
  width: 400px;
  max-width: 100%;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  z-index: 99;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  -ms-perspective: 1200px;
  perspective: 1200px
}

.ult_modal-auto-top .ult_modal {
  margin: 35px auto;
  top: 0;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0)
}

.ult_modal-content {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 7px #404040;
  -o-box-shadow: 0 0 7px #404040;
  -ms-box-shadow: 0 0 7px #404040;
  box-shadow: 0 0 7px #404040;
  overflow: hidden
}

.ult_modal-header {
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  background: #fff
}

.ult_modal-header>.modal-icon {
  font-size: 2em;
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 20px
}

h3.ult_modal-title {
  color: inherit;
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  padding: 10px 20px;
  font-size: 1em;
  line-height: 1.5em
}

.ult_modal-body {
  padding: 15px 20px;
  background: #fff;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.5em
}

.ult_modal-body p {
  margin-bottom: 1em
}

.ult_modal-body img {
  max-width: 100%
}

.ult-overlay iframe,.ult-overlay object {
  opacity: 0;
  position: absolute;
  z-index: -99999;
  max-width: 100%
}

.ult-overlay.ult-open iframe,.ult-overlay.ult-open object {
  opacity: 1;
  position: relative;
  z-index: 9999;
  z-index: 16777271
}

.ult_modal.ult-small {
  width: 35%
}

.ult_modal.ult-medium {
  width: 60%
}

.ult_modal.ult-container {
  width: 96%
}

.ult_modal.ult-block {
  width: auto;
  max-width: 90%;
  display: table
}

.overlay-fade {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,visibility .5s;
  transition: opacity .5s,visibility .5s
}

.overlay-fade.ult-open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity .5s;
  transition: opacity .5s
}

.overlay-fade .ult_modal-content {
  opacity: .4;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .5s,opacity .5s;
  -webkit-transition: opacity .5s,-webkit-transform .5s;
  transition: opacity .5s,-webkit-transform .5s;
  transition: transform .5s,opacity .5s;
  transition: transform .5s,opacity .5s,-webkit-transform .5s
}

.overlay-fade.ult-open .ult_modal-content {
  opacity: 1
}

.overlay-fade.ult-close .ult_modal-content {
  -webkit-transform: translateY(25%);
  -ms-transform: translateY(25%);
  transform: translateY(25%)
}

.overlay-corner {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px) translateX(50px);
  -ms-transform: translateY(50px) translateX(50px);
  transform: translateY(50px) translateX(50px);
  -webkit-transition: opacity .5s,-webkit-transform .5s,visibility .5s;
  -webkit-transition: opacity .5s,visibility .5s,-webkit-transform .5s;
  transition: opacity .5s,visibility .5s,-webkit-transform .5s;
  transition: opacity .5s,transform .5s,visibility .5s;
  transition: opacity .5s,transform .5s,visibility .5s,-webkit-transform .5s
}

.overlay-corner.ult-open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
  -webkit-transition: opacity .5s,-webkit-transform .5s;
  transition: opacity .5s,-webkit-transform .5s;
  transition: opacity .5s,transform .5s;
  transition: opacity .5s,transform .5s,-webkit-transform .5s
}

.overlay-cornerbottomleft,.overlay-cornerbottomright,.overlay-cornertopleft,.overlay-cornertopright {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,-webkit-transform .5s,visibility .5s;
  -webkit-transition: opacity .5s,visibility .5s,-webkit-transform .5s;
  transition: opacity .5s,visibility .5s,-webkit-transform .5s;
  transition: opacity .5s,transform .5s,visibility .5s;
  transition: opacity .5s,transform .5s,visibility .5s,-webkit-transform .5s
}

.overlay-cornerbottomleft.ult-open,.overlay-cornerbottomright.ult-open,.overlay-cornertopleft.ult-open,.overlay-cornertopright.ult-open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
  -webkit-transition: opacity .5s,-webkit-transform .5s;
  transition: opacity .5s,-webkit-transform .5s;
  transition: opacity .5s,transform .5s;
  transition: opacity .5s,transform .5s,-webkit-transform .5s
}

.overlay-cornerbottomright {
  -webkit-transform: translateY(200px) translateX(200px);
  -ms-transform: translateY(200px) translateX(200px);
  transform: translateY(200px) translateX(200px)
}

.overlay-cornerbottomleft {
  -webkit-transform: translateY(200px) translateX(-200px);
  -ms-transform: translateY(200px) translateX(-200px);
  transform: translateY(200px) translateX(-200px)
}

.overlay-cornertopleft {
  -webkit-transform: translateY(-200px) translateX(-200px);
  -ms-transform: translateY(-200px) translateX(-200px);
  transform: translateY(-200px) translateX(-200px)
}

.overlay-cornertopright {
  -webkit-transform: translateY(-200px) translateX(200px);
  -ms-transform: translateY(-200px) translateX(200px);
  transform: translateY(-200px) translateX(200px)
}

.overlay-slidedown,.overlay-slideleft,.overlay-slideright,.overlay-slideup {
  visibility: hidden;
  -webkit-transition: -webkit-transform .4s ease-in-out,visibility .4s;
  -webkit-transition: visibility .4s,-webkit-transform .4s ease-in-out;
  transition: visibility .4s,-webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out,visibility .4s;
  transition: transform .4s ease-in-out,visibility .4s,-webkit-transform .4s ease-in-out
}

.overlay-slidedown.ult-open,.overlay-slideleft.ult-open,.overlay-slideright.ult-open,.overlay-slideup.ult-open {
  visibility: visible;
  -webkit-transition: -webkit-transform .4s ease-in-out;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out,-webkit-transform .4s ease-in-out
}

.overlay-slidedown {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%)
}

.overlay-slidedown.ult-open {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.overlay-slideup {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%)
}

.overlay-slideup.ult-open {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.overlay-slideleft {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%)
}

.overlay-slideleft.ult-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0)
}

.overlay-slideright {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%)
}

.overlay-slideright.ult-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0)
}

.overlay-zoomin,.overlay-zoomout {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: -webkit-transform .2s,opacity .2s,visibility .2s;
  -webkit-transition: opacity .2s,visibility .2s,-webkit-transform .2s;
  transition: opacity .2s,visibility .2s,-webkit-transform .2s;
  transition: transform .2s,opacity .2s,visibility .2s;
  transition: transform .2s,opacity .2s,visibility .2s,-webkit-transform .2s
}

.overlay-zoomin.ult-open,.overlay-zoomout.ult-open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .4s,opacity .4s;
  -webkit-transition: opacity .4s,-webkit-transform .4s;
  transition: opacity .4s,-webkit-transform .4s;
  transition: transform .4s,opacity .4s;
  transition: transform .4s,opacity .4s,-webkit-transform .4s
}

.overlay-zoomin {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5)
}

.overlay-zoomout {
  -webkit-transform: scale(.5);
  -ms-transform: scale(.5);
  transform: scale(.5)
}

.overlay-doorhorizontal {
  visibility: hidden;
  width: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: width .5s .3s,visibility .8s;
  transition: width .5s .3s,visibility .8s
}

.overlay-doorhorizontal.ult-open {
  visibility: visible;
  width: 100%;
  -webkit-transition: width .5s;
  transition: width .5s
}

.overlay-doorhorizontal .ult-overlay-close,.overlay-doorhorizontal .ult_modal-content {
  opacity: 0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s
}

.overlay-doorhorizontal.ult-open .ult-overlay-close,.overlay-doorhorizontal.ult-open .ult_modal-content {
  opacity: 1;
  -webkit-transition-delay: .5s;
  transition-delay: .5s
}

.overlay-doorhorizontal.ult-close .ult-overlay-close,.overlay-doorhorizontal.ult-close .ult_modal-content {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.overlay-doorvertical {
  visibility: hidden;
  width: 100%;
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: height .5s .3s,visibility .8s;
  transition: height .5s .3s,visibility .8s
}

.overlay-doorvertical.ult-open {
  visibility: visible;
  height: 100%;
  -webkit-transition: height .5s;
  transition: height .5s
}

.overlay-doorvertical .ult-overlay-close,.overlay-doorvertical .ult_modal-content {
  opacity: 0;
  -webkit-transition: opacity .3s .5s;
  transition: opacity .3s .5s
}

.overlay-doorvertical.ult-open .ult-overlay-close,.overlay-doorvertical.ult-open .ult_modal-content {
  opacity: 1;
  -webkit-transition-delay: .5s;
  transition-delay: .5s
}

.overlay-doorvertical.ult-close .ult-overlay-close,.overlay-doorvertical.ult-close .ult_modal-content {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.overlay-cornershape {
  background: 0 0;
  visibility: hidden;
  -webkit-transition: visibility .5s;
  transition: visibility .5s
}

.overlay-cornershape.ult-open {
  visibility: visible;
  -webkit-transition: none;
  transition: none
}

.overlay-cornershape svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.overlay-cornershape .overlay-path {
  fill: rgba(28,28,28,.9)
}

.overlay-cornershape .ult-overlay-close,.overlay-cornershape .ult_modal-content {
  opacity: 0;
  -webkit-transition: opacity .4s;
  transition: opacity .4s
}

.overlay-cornershape.ult-open .ult-overlay-close,.overlay-cornershape.ult-open .ult_modal-content {
  opacity: 1;
  -webkit-transition-delay: .4s;
  transition-delay: .4s
}

.overlay-cornershape.ult-close .ult-overlay-close,.overlay-cornershape.ult-close .ult_modal-content {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.overlay-boxes {
  background: 0 0;
  visibility: hidden;
  -webkit-transition: visibility .8s;
  transition: visibility .8s
}

.overlay-boxes.ult-open {
  visibility: visible;
  -webkit-transition: none;
  transition: none
}

.overlay-boxes svg {
  position: absolute;
  top: 0;
  left: 0
}

.overlay-boxes svg path {
  fill: rgba(28,28,28,1);
  display: none
}

.overlay-boxes .ult-overlay-close,.overlay-boxes .ult_modal-content {
  opacity: 0;
  -webkit-transition: opacity .5s .8s;
  transition: opacity .5s .8s
}

.overlay-boxes.ult-open .ult-overlay-close,.overlay-boxes.ult-open .ult_modal-content {
  opacity: 1;
  -webkit-transition-delay: .8s;
  transition-delay: .8s
}

.overlay-boxes.ult-close .ult-overlay-close,.overlay-boxes.ult-close .ult_modal-content {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.overlay-boxes {
  background: 0 0;
  visibility: hidden;
  -webkit-transition: visibility .8s;
  transition: visibility .8s
}

.overlay-boxes.ult-open {
  visibility: visible;
  -webkit-transition: none;
  transition: none
}

.overlay-boxes svg {
  position: absolute;
  top: 0;
  left: 0
}

.overlay-boxes svg path {
  fill: rgba(28,28,28,1);
  display: none
}

.overlay-boxes .ult-overlay-close {
  opacity: 0;
  -webkit-transition: opacity .5s .8s;
  transition: opacity .5s .8s
}

.overlay-boxes.ult-open .ult-overlay-close {
  opacity: 1;
  -webkit-transition-delay: .8s;
  transition-delay: .8s
}

.overlay-boxes.ult-close .ult-overlay-close {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.overlay-simplegenie {
  visibility: hidden;
  -webkit-transform: translateY(60%) scale(0);
  -ms-transform: translateY(60%) scale(0);
  transform: translateY(60%) scale(0);
  -webkit-transition: -webkit-transform .4s,visibility .4s;
  -webkit-transition: visibility .4s,-webkit-transform .4s;
  transition: visibility .4s,-webkit-transform .4s;
  transition: transform .4s,visibility .4s;
  transition: transform .4s,visibility .4s,-webkit-transform .4s
}

.overlay-simplegenie.ult-open {
  visibility: visible;
  -webkit-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  transition: transform .4s;
  transition: transform .4s,-webkit-transform .4s
}

.overlay-genie {
  background: 0 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s .2s,visibility .6s;
  transition: opacity .3s .2s,visibility .6s
}

.overlay-genie.ult-open {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity .3s;
  transition: opacity .3s
}

.overlay-genie svg {
  position: fixed;
  top: 0;
  left: 0
}

.overlay-genie .overlay-path {
  fill: rgba(28,28,28,.9)
}

.overlay-genie .ult_modal-content {
  opacity: 0;
  -webkit-transform: scale(.8) translateY(-48%);
  -ms-transform: scale(.8) translateY(-48%);
  transform: scale(.8) translateY(-48%);
  -webkit-transition: opacity .3s .3s,-webkit-transform .3s .3s;
  transition: opacity .3s .3s,-webkit-transform .3s .3s;
  transition: opacity .3s .3s,transform .3s .3s;
  transition: opacity .3s .3s,transform .3s .3s,-webkit-transform .3s .3s
}

.overlay-genie .ult-overlay-close {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s
}

.overlay-genie.ult-open .ult-overlay-close,.overlay-genie.ult-open .ult_modal-content {
  opacity: 1;
  -webkit-transition-delay: .3s;
  transition-delay: .3s
}

.overlay-genie.ult-open .ult_modal-content {
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0)
}

.overlay-genie.ult-close .ult-overlay-close,.overlay-genie.ult-close .ult_modal-content {
  -webkit-transition-delay: 0;
  transition-delay: 0
}

.ult_modal div.ult_modal-body>iframe {
  display: none
}

.ult-open>.ult_modal div.ult_modal-body>iframe {
  display: block
}

div.ult_modal-body .fluid-width-video-wrapper {
  padding: 0!important
}

@media(max-width: 767px) {
  .ult_modal.ult-container,.ult_modal.ult-medium,.ult_modal.ult-small {
      width:80%
  }

  .ult_modal.ult-block {
      max-width: 80%
  }

  .ult-overlay-close {
      width: 24px;
      height: 24px;
      right: 5px;
      top: 5px
  }
}

  */
  @media screen and (max-width: 1492px) {
    .App-header{
      /* overflow: hidden; */
    }
  }
  @media screen and (max-width: 768px) {
    .App-header{
      overflow: hidden;
    }
  }