.footer_container{
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-left: 161px;
    padding-right: 161px;
    background: rgb(196, 196, 196);
}
@media screen and (max-width:768px) {
    .footer_container{
        display: flex;
        justify-content: center;
        background: rgb(196, 196, 196);
    }
}