.image_icon{
    width: 600px;
}
@media screen and (max-width:768px) {
    .image_icon{
        width: 300px;
    }
}
.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 9999; 
    opacity: 0; 
    animation: zoom-out 0.5s ease-in-out;
  }
  @keyframes zoom-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes zoom-out {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes custom-animation {
    0% {
      transform: rotate(0deg) scale(0);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  .image-popup.show {
    opacity: 1;
    animation: zoom-in 0.5s ease-in-out;
    /* animation: custom-animation 0.5s ease-in-out; */
  }
  
  @media screen and (max-width:768px) {
    .image-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999; 
        opacity: 0; 
        transition: opacity 0.3s ease-in-out; 
      }
}
  .image-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
  }
  
  .img-full-screen {
    max-width: 100%;
    max-height: 100%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #000;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }