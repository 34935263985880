.location_container {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    /* padding-left: 161px; */
    /* padding-right: 161px; */
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    .location_container .map-container {
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
    }
    }
    .image-container{
        margin-top: 20px;
        /* background-color: red; */
      }
      @media screen and (max-width:768px) {
         .image-container{
          /* background-color: yellow; */
        }  
      }
      .image-container .image-size{
         padding-left: 161px;
        padding-right: 161px;  
      }
      @media screen and (max-width:768px) {
        .image-container .image-size{
          display:none;
        }
      }