.slick-slide {
    margin: 0 10px; 
    margin-top: 30px;
    margin-bottom: -100px;
    float: left;
  } 
  .slider-part{
    display: block;
    /* width: 100%; */
    /* max-width: 100%; */
    margin: 0 auto;
    overflow: hidden;
  }
  .slideStyle {
    height: 420px;
    max-width: 100%;
    border: 5px solid #374f5a;
  }
  @media screen and (max-width:768px) {
    .slideStyle {
      height: 350px;
      width: 420px;
      border: 5px solid #374f5a;
    }
}